import React from "react";
import {LoanInfo} from "../ShowLoan";
import {Tab} from "semantic-ui-react";
import {LoanExtension} from "./transactions/LoanExtension";
import LoanReschedules from "./transactions/LoanReschedules";
import LoanPenalties from "./transactions/LoanPenalties";
import RemoveInterest from "./transactions/RemoveInterest";
import LoanHistory from "./transactions/LoanHistory";
import JournalEntries from "./transactions/JournalEntries";

export default function OtherTransactions(params: { loanInfo: LoanInfo, setLoanInfo: (loanInfo: LoanInfo) => void }) {
    return (
        <Tab className='loan_other_data'
             panes={[
                 {
                     menuItem: 'Restructuring',
                     render() {
                         return (
                             <Tab.Pane attached={false} className="other_loan_pane" key='loan_scheduling'>
                                 <LoanReschedules loanInfo={params.loanInfo} setLoanInfo={params.setLoanInfo}/>
                             </Tab.Pane>
                         )
                     }
                 },
                 {
                     menuItem: 'Loan Extensions',
                     render() {
                         return (
                             <Tab.Pane attached={false} className="other_loan_pane" key='loan_re_structuring'>
                                 <LoanExtension loanInfo={params.loanInfo} setLoanInfo={params.setLoanInfo}/>
                             </Tab.Pane>
                         )
                     }
                 },
                 {
                     menuItem: 'Penalties',
                     render() {
                         return (
                             <Tab.Pane attached={false} className="other_loan_pane" key='loan_penalties'>
                                 <LoanPenalties loanInfo={params.loanInfo} setLoanInfo={params.setLoanInfo}/>
                             </Tab.Pane>
                         )
                     }
                 },
                 {
                     menuItem: 'Waive Off Loan',
                     render() {
                         return (
                             <Tab.Pane attached={false} className="other_loan_pane" key='loan_scheduling'>
                                 <RemoveInterest loanInfo={params.loanInfo} setLoanInfo={params.setLoanInfo}/>
                             </Tab.Pane>
                         )
                     }
                 },
                 {
                     menuItem: 'Loan History',
                     render() {
                         return (
                             <Tab.Pane attached={false} className="other_loan_pane" key='loan_history'>
                                 <LoanHistory loanInfo={params.loanInfo}/>
                             </Tab.Pane>
                         )
                     }
                 },
                 {
                     menuItem: 'Journal Entries',
                     render() {
                         return (
                             <Tab.Pane attached={false} className="other_loan_pane" key='journal_entries'>
                                 <JournalEntries loanInfo={params.loanInfo}/>
                             </Tab.Pane>
                         )
                     }
                 },
             ]}/>
    )
}
