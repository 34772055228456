import React from "react";
import {Button, Modal} from "semantic-ui-react";
import {Req} from "../../../../utils/Req";

export default function ChangePassword(
    props: { show: boolean, close: (status: boolean, password: string) => void, credential: string, logged_in: boolean }
) {
    const [userData, setUserData] = React.useState<{
        credential: string,
        new_password: string,
        current_password: string,
        confirm_password: string
    }>(
        {credential: "", new_password: "", current_password: "", confirm_password: ""}
    )
    const [error, setError] = React.useState("")
    const [loading, setLoading] = React.useState(false)

    React.useEffect(() => {
        setUserData({...userData, 'credential': props.credential})
    }, [props.credential])

    const change_password = () => {
        setError("")
        if (props.logged_in && userData.current_password.length < 6) {
            setError("Enter your valid current password")
        } else if (userData.new_password.length < 6) {
            setError("New password must be at least 6 characters")
        } else if (userData.current_password === userData.new_password) {
            setError("Current password must not be equal to current password")
        } else if (userData.new_password !== userData.confirm_password) {
            setError("New password and confirmation do not match")
        } else {
            setLoading(true)
            Req.change_password(
                {
                    credential: userData.credential,
                    new_password: userData.new_password,
                    current_password: userData.current_password
                })
                .then(response => {
                    setLoading(false)
                    if (response.data['code'] === 1) {
                        props.close(true, userData.new_password)
                    } else {
                        setError(response.data.msg)
                    }
                })
                .catch(() => {
                    setLoading(false)
                    setError("Could not login at the moment, please retry")
                })
        }
    }

    return (
        <Modal size='mini' open={props.show} onClose={() => props.close(false, "")}
               closeOnDocumentClick={true} closeIcon={true}>
            <div className="login_modal">
                {
                    props.logged_in ?
                        <h2>Change Password</h2> :
                        <div className="ui info message show">Set a password to your account in order to proceed</div>
                }

                <div className="ui large form">
                    <div className="ui stacked secondary  segment">

                        {/*current password*/}
                        {
                            props.logged_in &&
                            <div className="field">
                                <div className="ui left icon input">
                                    <i className="lock icon"/>
                                    <input type="password" placeholder="Current password" value={userData.current_password}
                                           onChange={(event) => {
                                               setUserData({...userData, 'current_password': event.target.value})
                                           }}/>
                                </div>
                            </div>
                        }


                        {/*new password*/}
                        <div className="field">
                            <div className="ui left icon input">
                                <i className="lock icon"/>
                                <input type="password" placeholder="New password" value={userData.new_password}
                                       onChange={(event) => {
                                           setUserData({...userData, 'new_password': event.target.value})
                                       }}/>
                            </div>
                        </div>

                        {/*confirm password*/}
                        <div className="field">
                            <div className="ui left icon input">
                                <i className="lock icon"/>
                                <input type="password" placeholder="Confirm password" value={userData.confirm_password}
                                       onChange={(event) => {
                                           setUserData({...userData, 'confirm_password': event.target.value})
                                       }}/>
                            </div>
                        </div>

                        <Button className="ui button fluid teal" size='small' loading={loading} disabled={loading}
                                onClick={change_password}>
                            Update Password
                        </Button>
                    </div>
                    <div className={`ui error message ${error === "" ? '' : 'show'}`}>{error}</div>
                </div>
            </div>
        </Modal>
    )
}
