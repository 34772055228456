import {Req} from "../../../../utils/Req";
import {ToastsStore} from "react-toasts";
import {Loading, MyLabel} from "../../../../utils/Components";
import React from "react";
import {Button, Form, Modal, TextArea} from "semantic-ui-react";

export default function DeleteLoan(params: { show: boolean, loan_id: number, close: (deleted: boolean) => void }) {
    const [loading, setLoading] = React.useState({show: false, title: ""})
    const [deleteLoan, setDeleteLoan] = React.useState({reason: ''})

    const delete_loan = () => {
        if (deleteLoan.reason.trim().length < 10) {
            ToastsStore.error("Enter a valid reason for deleting the loan")
        } else {
            setLoading({show: true, title: "Deleting loan, please wait"})
            Req.delete_loan({loan_id: params.loan_id, delete_reason: deleteLoan.reason})
                .then((response) => {
                    setLoading({show: false, title: ""})
                    if (response.data.hasOwnProperty("code")) {
                        if (response.data.code === 1) {
                            ToastsStore.success("Loan deleted successfully")
                            params.close(true)
                        } else if (response.data.code === 2) {
                            ToastsStore.error("Loan cannot be deleted")
                            params.close(false)
                        }
                    } else {
                        ToastsStore.error("Loan could not be deleted, please retry")
                    }
                })
                .catch(() => {
                    setLoading({show: false, title: ""})
                    ToastsStore.error("Loan could not be deleted, please retry")
                })
        }
    }

    React.useEffect(() => {
        setDeleteLoan({reason: ""})
    }, [params.show])

    return (
        <>
            <Loading show={loading.show} text={loading.title}/>

            <Modal open={params.show} basic size='mini' centered={false}>
                <div className="modal_div">
                    <div className="form_header">Delete Loan</div>

                    <div className="form_content">
                        <MyLabel title="Reason for deleting loan"/>
                        <Form>
                            <TextArea
                                placeholder='Enter reason for deleting loan' rows={3} value={deleteLoan.reason}
                                onChange={((event, data) => {
                                    setDeleteLoan({reason: data.value as string})
                                })}/>
                        </Form>
                    </div>

                    <div className="form_footer mt-3">
                        <div className="row m-0">
                            <div className="col-6 pl-0 pr-1">
                                <Button negative onClick={() => params.close(false)} content="Close Window" size='mini' fluid
                                        icon='close' labelPosition={"left"}/>
                            </div>
                            <div className="col-6 pl-1 pr-0">
                                <Button positive onClick={delete_loan} content="Delete Loan" size='mini' fluid
                                        icon="trash" labelPosition={"left"}/>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}
