import React from "react";
import {LoanChargeTier} from "./LoanTypes";
import Utils from "../../../utils/Utils";
import {ToastsStore} from "react-toasts";
import {Button, Modal} from "semantic-ui-react";
import {MyInput, MySelect} from "../../../utils/Components";

export default function EditCharge(params: { index: number, tier: LoanChargeTier, show: boolean, close: (tier?: LoanChargeTier) => void }) {
    const [tier, setTier] = React.useState<LoanChargeTier>(params.tier)
    const handle_tier = (name: string, value: string) => {
        setTier({...tier, [name]: value})
    }

    const save_tier = () => {
        if (!Utils.is_valid_number(Utils.strip_commas(tier.min_value)) || parseFloat(Utils.strip_commas(tier.min_value)) < 0) {
            ToastsStore.error(`Enter valid minimum principal this charge is made on`)
        } else if (!Utils.is_valid_number(Utils.strip_commas(tier.charge_value)) || parseFloat(Utils.strip_commas(tier.charge_value)) < 0) {
            ToastsStore.error(`Value charged cannot be less than 0"`)
        } else if (parseFloat(Utils.strip_commas(tier.charge_value)) > 100 && tier.charge_type === "Percentage") {
            ToastsStore.error(`Value charged cannot be greater than 100`)
        } else {
            params.close(tier)
        }
    }

    React.useEffect(() => {
        setTier(params.tier)
    }, [params.tier])

    return (
        <Modal open={params.show} basic size='mini' centered={false}>
            <div className='modal_div loan_types'>
                <div className="form">
                    <div className="form_content">
                        <MyInput
                            placeholder="Enter amount" name="min_value" title="Minimum principal amount"
                            change={(name, value) => handle_tier(name, value)} value={Utils.comma_input(tier.min_value)}/>

                        <MySelect
                            value={tier.charge_type} name="charge_type" title="Loan charge type"
                            onChange={(name, value) => {
                                if (value === 'Custom') {
                                    setTier({...tier, charge_type: 'Custom', charge_value: '0'})
                                } else {
                                    setTier({...tier, charge_type: value as 'Percentage' | 'Flat', charge_value: ''})
                                }
                            }}
                            options={[
                                {value: "Flat", text: "Flat Charge"},
                                {value: "Percentage", text: "Percentage of principal"},
                                {value: "Custom", text: "Charge entered manually"}
                            ]}/>

                        <MyInput
                            placeholder="Enter value" name="charge_value" className="mb-0" disabled={tier.charge_type === 'Custom'}
                            title={tier.charge_type === 'Percentage' ? 'Charge Percentage' : 'Amount to be charged'}
                            change={(name, value) => handle_tier(name, value)} value={Utils.comma_input(tier.charge_value)}/>
                    </div>

                    <div className="form_footer">
                        <div className='row m-0'>
                            <div className='col-6 pl-0 pr-1'>
                                <Button negative onClick={() => params.close()} content='Discard' fluid size='tiny' icon='close' labelPosition='left'/>
                            </div>
                            <div className='col-6 pl-1 pr-0'>
                                <Button positive onClick={save_tier} content='Save Data' fluid size='tiny' icon='save' labelPosition='left'/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}
