import {MyDateInput, SelectData} from "../../../utils/Components";
import React from "react";
import Utils from "../../../utils/Utils";
import {MultiSelect} from "../../../utils/components/MultiSelect";
import {Button, Table} from "semantic-ui-react";
import {ToastsStore} from "react-toasts";
import {Req} from "../../../utils/Req";

interface Projection {
    data: {
        interest: number
        office_id: number
        principal: number
        charges: number
    }[]
    date: string
    total: number
}

export default function PaymentsProjection(params: { branches: { select: SelectData[], selected: number[] } }) {
    const [loading, setLoading] = React.useState(false)
    const [search, setSearch] = React.useState({branches: params.branches.selected, min: Utils.today(), max: Utils.today()})
    const handle_search_change = (name: string, value: string | number | Array<number | string>) => {
        setSearch({...search, [name]: value})
    }

    const [projections, setProjections] = React.useState(Array<Projection>())

    const search_payments = () => {
        if (search.branches.length === 0) {
            ToastsStore.error("No branches selected, reload page if branches select is empty")
            return
        }
        setLoading(true)
        Req.get_loan_payment_projections({branches: JSON.stringify(search.branches), max_date: search.max, min_date: search.min})
            .then((response) => {
                setLoading(false)
                if (response.data.hasOwnProperty("projections")) {
                    setProjections(response.data['projections'])
                } else {
                    ToastsStore.error("Failed to get payments projections")
                }
            })
            .catch(() => {
                setLoading(false)
                ToastsStore.error("Failed to get payments projections")
            })
    }

    const get_branch_data = (branch: SelectData, projection: Projection) => {
        const aData = projection.data.filter((info) => info.office_id === branch.value)
        if (aData.length === 1) {
            return (
                <>
                    <Table.Cell style={{width: '60px'}}>{aData[0].charges > 0 && Math.round(aData[0].charges).toLocaleString()}</Table.Cell>
                    <Table.Cell style={{width: '60px'}}>{aData[0].interest > 0 && Math.round(aData[0].interest).toLocaleString()}</Table.Cell>
                    <Table.Cell style={{width: '60px'}}>{aData[0].principal > 0 && Math.round(aData[0].principal).toLocaleString()}</Table.Cell>
                    <Table.Cell style={{width: '60px'}}>{Math.round(aData[0].interest + aData[0].principal).toLocaleString()}</Table.Cell>
                </>
            )
        } else {
            return <>
                <Table.Cell style={{width: '60px'}}></Table.Cell>
                <Table.Cell style={{width: '60px'}}></Table.Cell>
                <Table.Cell style={{width: '60px'}}></Table.Cell>
                <Table.Cell style={{width: '60px'}}></Table.Cell>
            </>
        }
    }

    return (
        <>
            <div className="content_bar">
                <div className="search_bar">
                    <div>
                        <MultiSelect
                            multiple={true} placeholder="Select offices" items={params.branches.select} selected={search.branches}
                            change={(value) => handle_search_change('branches', value)}/>
                    </div>
                    <div>
                        <MyDateInput
                            title="" value={search.min} name="min" placeholder="Minimum Date"
                            change={handle_search_change} minDate={Utils.today()} maxDate={search.max}/>
                    </div>
                    <div>
                        <MyDateInput
                            title="" value={search.max} name="max" placeholder="Maximum Date"
                            change={handle_search_change} minDate={search.min}/>
                    </div>
                </div>
                <div className="button_bar">
                    <Button floated='right' size='mini' icon='search' disabled={loading} loading={loading}
                            onClick={search_payments} content="Search" primary labelPosition="left"/>
                </div>
            </div>

            <div className="table_container container_no_footer">
                <Table celled striped compact size='small' inverted structured color='grey' selectable>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell style={{width: '80px'}} rowSpan={2}>Date</Table.HeaderCell>
                            {
                                params.branches.select.map((branch) =>
                                    <Table.HeaderCell style={{width: '240px'}} key={branch.value} colSpan={4} textAlign={'center'}>{branch.text}</Table.HeaderCell>
                                )
                            }
                            <Table.HeaderCell style={{width: '80px'}} rowSpan={2}>Total</Table.HeaderCell>
                        </Table.Row>
                        <Table.Row>
                            {
                                params.branches.select.map((branch) =>
                                    <>
                                        {
                                            ["Charges", "Interest", "Principal", "Total"].map((header) =>
                                                <Table.HeaderCell style={{width: '60px'}} key={branch.value + '_' + header}>{header}</Table.HeaderCell>
                                            )
                                        }
                                    </>
                                )
                            }
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {
                            projections.map((projection) =>
                                <Table.Row key={projection.date}>
                                    <Table.Cell style={{width: '80px'}}>{projection.date}</Table.Cell>
                                    {params.branches.select.map((branch) => get_branch_data(branch, projection))}
                                    <Table.Cell style={{width: '80px'}}>{Math.round(projection.total).toLocaleString()}</Table.Cell>
                                </Table.Row>
                            )
                        }
                    </Table.Body>
                </Table>
            </div>
        </>
    )
}
