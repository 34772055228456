import {LoanInfo} from "../../ShowLoan";
import React from "react";
import Utils, {ConfirmAction, initial_confirm} from "../../../../../utils/Utils";
import {ToastsStore} from "react-toasts";
import {Req} from "../../../../../utils/Req";
import {Loading, MyInput, MyLabel} from "../../../../../utils/Components";
import {Button, Checkbox, Confirm, Form, Table, TextArea} from "semantic-ui-react";
import {useAppSelector} from "../../../../../store/hooks";
import {getStaff} from "../../../../../store/slices/userSlice";

export interface DeductInterest {
    removal_id: number
    principal_waived: number
    interest_waived: number
    penalty_waived: number
    total_waived: number
    reason_removed: string
    date_removed: string
    removed_by: string
}

export default function RemoveInterest(params: { loanInfo: LoanInfo, setLoanInfo: (loanInfo: LoanInfo) => void }) {
    const user = useAppSelector(getStaff)
    const [confirm, setConfirm] = React.useState<ConfirmAction>(initial_confirm)
    const [loader, setLoader] = React.useState({message: "", show: false})

    const initial_deduct = {
        removal_id  : 0, removed_by: "", principal_waived: "", interest_waived: "", penalty_waived: "",
        date_removed: Utils.today(), reason_removed: "", write_off: false, bad_loan: false
    }
    const [deduct, setDeduct] = React.useState(initial_deduct)
    const handle_deduct = (name: string, value: string) => {
        setDeduct({...deduct, [name]: value})
    }

    const waive_loan = () => {
        const principal = Utils.strip_commas(deduct.principal_waived)
        const interest = Utils.strip_commas(deduct.interest_waived)
        const penalty = Utils.strip_commas(deduct.penalty_waived)

        if (!deduct.write_off && (!Utils.is_valid_number(principal) || parseFloat(principal) < 0) && principal !== '') {
            ToastsStore.error("Enter valid principal amount to be waived off")
        } else if (!deduct.write_off && (!Utils.is_valid_number(interest) || parseFloat(interest) < 0)) {
            ToastsStore.error("Enter valid interest amount to be waived off")
        } else if (!deduct.write_off && (!Utils.is_valid_number(interest) || parseFloat(interest) < 0)) {
            ToastsStore.error("Enter valid principal amount to be waived off")
        } else if (!deduct.write_off && (interest === '' || parseFloat(interest) === 0) && (penalty === '' || parseFloat(penalty) === 0)
            && (principal === '' || parseFloat(principal) === 0)) {
            ToastsStore.error("Principal, interest and penalty to be waived off all cannot be 0")
        } else {
            setConfirm({
                ...confirm, 'open': true, 'content': `Are you sure you want to proceed with waiving off interest from this loan?`,
                'onConfirm'       : () => {
                    setConfirm({...confirm, 'open': false})
                    setLoader({message: "Waiving off interest, please wait", show: true})

                    Req.waive_loan({
                        principal_waived: (deduct.write_off || principal === '') ? 0 : parseFloat(principal),
                        interest_waived : (deduct.write_off || interest === '') ? 0 : parseFloat(interest),
                        penalty_waived  : (deduct.write_off || penalty === '') ? 0 : parseFloat(penalty),
                        write_off       : deduct.write_off ? 'Yes' : 'No',
                        bad_loan        : deduct.bad_loan ? 'Yes' : 'No',
                        loan_id         : params.loanInfo.loan_id,
                        reason_removed  : deduct.reason_removed,

                    })
                        .then((response) => {
                            setLoader({message: "", show: false})
                            if (response.data.hasOwnProperty("code")) {
                                if (response.data.code === 1) {
                                    ToastsStore.success("Successfully removed loan interest")
                                    setDeduct(initial_deduct)
                                    params.setLoanInfo(Utils.format_loan_data(response))
                                } else if (response.data.code === 2) {
                                    ToastsStore.error(`Maximum amount of interest ${response.data.amount} can be deducted from the loan`);
                                } else if (response.data.code === 3) {
                                    ToastsStore.error(`Payments made cannot write off the loan`)
                                } else if (response.data.code === 4) {
                                    ToastsStore.info(`Loan was already completed`)
                                }
                            } else {
                                ToastsStore.error("Cannot deduct interest from loan, please retry")
                            }
                        })
                        .catch(() => {
                            setLoader({message: "", show: false})
                            ToastsStore.error("Cannot deduct interest from loan, please retry")
                        })
                },
                'onCancel'        : () => setConfirm({...confirm, 'open': false})
            })
        }
    }

    return (
        <>
            <Loading show={loader.show} text={loader.message} hide={() => setLoader({...loader, show: false})}/>

            <Confirm open={confirm.open} onCancel={confirm.onCancel} onConfirm={confirm.onConfirm} centered={false} size='mini'
                     cancelButton={confirm.cancelButton} confirmButton={confirm.confirmButton} content={confirm.content}/>

            <div className="row mx-0 h-100">
                <div className="col-12 p-1 col-md-8 h-100">
                    <div className="table_container full_height">
                        <Table celled striped compact size='small' inverted color='grey' selectable>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell style={{width: '50px'}} textAlign={'center'} rowSpan={2}>No.</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '140px'}} rowSpan={2}>Date Removed</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '150px'}} rowSpan={2}>Waived Off By</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '400px'}} textAlign={'center'} colSpan={4}>Amounts Waived</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '250px'}} rowSpan={2}>Reason Removed</Table.HeaderCell>
                                </Table.Row>

                                <Table.Row>
                                    <Table.HeaderCell style={{width: '100px'}}>Principal</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '100px'}}>Interest</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '100px'}}>Penalty</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '100px'}}>Total</Table.HeaderCell>
                                </Table.Row>

                            </Table.Header>

                            <Table.Body>
                                {
                                    params.loanInfo.others.removals
                                        .map((info, index) =>
                                            <Table.Row key={info.removal_id}>
                                                <Table.Cell style={{width: '50px'}} textAlign={'center'}>{Utils.row_number(index)}</Table.Cell>
                                                <Table.Cell style={{width: '140px'}}>{Utils.localise_date(info.date_removed)}</Table.Cell>
                                                <Table.Cell style={{width: '150px'}}>{info.removed_by}</Table.Cell>
                                                <Table.Cell style={{width: '100px'}}>{Utils.comma_number(info.principal_waived)}</Table.Cell>
                                                <Table.Cell style={{width: '100px'}}>{Utils.comma_number(info.interest_waived)}</Table.Cell>
                                                <Table.Cell style={{width: '100px'}}>{Utils.comma_number(info.penalty_waived)}</Table.Cell>
                                                <Table.Cell style={{width: '100px'}}>{Utils.comma_number(info.total_waived)}</Table.Cell>
                                                <Table.Cell style={{width: '250px'}}>{info.reason_removed}</Table.Cell>
                                            </Table.Row>
                                        )
                                }
                            </Table.Body>
                        </Table>
                    </div>
                </div>

                <div className="col-12 p-1 col-md-4 h-100">
                    <div className="form">
                        <div className='form_content'>
                            <div className={"mb-2"}>
                                <Checkbox
                                    checked={deduct.write_off} label="Write off whole loan" className={"mb-2"}
                                    onChange={(_event, data) => setDeduct({...deduct, write_off: data.checked as boolean, bad_loan: false})}/>
                            </div>

                            {
                                deduct.write_off &&
                                <div className={"mb-2"}>
                                    <Checkbox
                                        checked={deduct.bad_loan} label="Mark as bad loan"
                                        onChange={(_event, data) => setDeduct({...deduct, bad_loan: data.checked as boolean})}/>
                                </div>
                            }

                            {
                                !deduct.write_off &&
                                <>
                                    <MyInput
                                        placeholder="Principal to be waived off" name="principal_waived" title={`Principal Waived`}
                                        value={Utils.comma_input(deduct.principal_waived)}
                                        change={(name, value) => setDeduct({...deduct, [name]: value})}/>

                                    <MyInput
                                        placeholder="Interest to be waived off" name="interest_waived" title={`Interest Waived`}
                                        value={Utils.comma_input(deduct.interest_waived)}
                                        change={(name, value) => setDeduct({...deduct, [name]: value})}/>

                                    <MyInput
                                        placeholder="Penalty to be waived off" name="penalty_waived" title={`Penalty Waived`}
                                        value={Utils.comma_input(deduct.penalty_waived)}
                                        change={(name, value) => setDeduct({...deduct, [name]: value})}/>
                                </>
                            }

                            <div style={{marginTop: '15px'}}>
                                <MyLabel title="Reason for waiving"/>
                                <Form>
                                    <TextArea
                                        placeholder='Enter reason for waiving' rows={5} value={deduct.reason_removed}
                                        onChange={((_event, data) => handle_deduct('reason_removed', data.value as string))}/>
                                </Form>
                            </div>
                        </div>

                        <div className="form_footer">
                            <div className="row m-0">
                                <div className="col-6 pl-0 pr-1">
                                    <Button
                                        negative onClick={() => setDeduct(initial_deduct)} labelPosition={"left"}
                                        icon='close' content="Clear Data" size='mini' fluid
                                        disabled={
                                            !user.roles.core_roles?.includes("waive_interest") || params.loanInfo.payments.summary.principal.balance < 1
                                        }/>
                                </div>
                                <div className="col-6 pr-0 pl-1">
                                    <Button
                                        positive onClick={() => waive_loan()} labelPosition={"left"}
                                        content={deduct.write_off ? "Write Off" : "Waive Loan"} size='mini' fluid icon="save"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
