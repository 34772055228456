import avatar from "../../assets/images/cd-avatar.svg";
import React from "react";
import {useAppDispatch, useAppSelector} from "../store/hooks";
import {getStaff, logoutUser} from "../store/slices/userSlice";
import {Req} from "../utils/Req"
import Home from "../modules/home/Home";
import {Rights} from "../modules/admin/settings/AccessRights"
import {Loading, LoadingParams, PDFReader} from "../utils/Components"
import {CreateLoan} from "../modules/loans/manage/CreateLoan";
import TillAllocations from "../modules/tills/TillAllocations";
import LoanActions from "../modules/loans/manage/LoanActions";
import InspectionReports from "../modules/loans/manage/InspectionReports";
import TillBalances from "../modules/tills/TillBalances";
import SystemCreditors from "../modules/accounts/creditors/SystemCreditors";
import SystemReports from "../modules/reports/SystemReports";
import Users from "../modules/admin/users/Users";
import LoanTypes from "../modules/admin/loans/LoanTypes";
import LegalDocuments from "../modules/admin/LegalDocuments";
import Settings from "../modules/admin/settings/Settings";
import Messaging from "../modules/clients/Messaging";
import Clients from "../modules/clients/Clients"
import Utils, {ConfirmAction, initial_confirm} from "../utils/Utils";
import CashTransfers from "../modules/accounts/bank/CashTransfers"
import ExpenseManagement from "../modules/accounts/expenses/ExpenseManagement";
import SalaryManagement from "../modules/accounts/salary/SalaryManagement"
import {Confirm} from "semantic-ui-react";
import {ToastsStore} from "react-toasts";
import {getCompanyInfo} from "../store/slices/systemSlice";
import localforage from "localforage";
import DueLoans, {due_periods} from "../modules/loans/listing/DueLoans"
import LoansPortfolio, {PortFolioLoan} from "../modules/loans/listing/LoansPortfolio";
import CompleteLoans from "../modules/loans/listing/CompleteLoans";
import PaymentArrears, {arrears_periods} from "../modules/loans/listing/PaymentArrears";
import LoanListing from "../modules/loans/listing/LoanListing";
import AccountReconciliations from "../modules/accounts/reconciliations/AccountReconciliations";
import {Account} from "../utils/Models";
import DirectorDrawings from "../modules/accounts/bank/DirectorDrawings";
import PaymentsProjection from "../modules/loans/listing/PaymentsProjection";

export interface BranchUser {
    branches: Array<{ office_name: string, office_id: number }>
    users: Array<{ user_name: string, user_id: number, user_status: 'active' | 'inactive', office_id: number }>
}

export default function SideBar() {
    const user = useAppSelector(getStaff)
    const company = useAppSelector(getCompanyInfo)
    const dispatch = useAppDispatch()

    const [loading, setLoading] = React.useState<LoadingParams>({text: "Loading events, please wait", show: false})

    const [branchesUsers, setBranchesUsers] = React.useState<BranchUser>({branches: [], users: []})
    const [accounts, setAccounts] = React.useState(Array<Account>())
    const [loanPortFolio, setLoanPortfolio] = React.useState(Array<PortFolioLoan>())

    const [showReader, setShowReader] = React.useState({display: false, source: ""})
    const [confirm, setConfirm] = React.useState<ConfirmAction>(initial_confirm)

    const page_wrapper = React.useRef<HTMLDivElement | null>(null)
    const show_sidebar = React.useRef<HTMLDivElement | null>(null)
    const close_sidebar = React.useRef<HTMLDivElement | null>(null)
    const sidebar = React.useRef<HTMLElement | null>(null)

    const [page, setPage] = React.useState<{ document: any, title: string, name: string }>({document: <Home/>, title: "Home", name: 'home'})


    React.useLayoutEffect(() => {
        (close_sidebar.current as HTMLDivElement).addEventListener('click', function () {
            (page_wrapper.current as HTMLDivElement).classList.remove("toggled")
        });
        (show_sidebar.current as HTMLDivElement).addEventListener('click', function () {
            (page_wrapper.current as HTMLDivElement).classList.add("toggled")
        });

        const dropdowns = document.getElementsByClassName("sidebar_dropdown")
        const sidebar_submenu = document.getElementsByClassName("sidebar_submenu")

        Array.from(dropdowns).forEach((element, index) => {
            (element as HTMLLIElement).querySelectorAll(':scope > span.menu_item')
                .forEach((menuElement) => {
                    const menu_item = menuElement as HTMLSpanElement
                    const parent = menu_item.parentElement as HTMLDivElement

                    menu_item.addEventListener('click', function () {
                        /*first hiding all the sidebars submenus*/
                        Array.from(sidebar_submenu).forEach(function (submenu) {
                            (submenu as HTMLDivElement).classList.remove("show");
                        });
                        /*making all dropdowns inactive*/
                        Array.from(dropdowns).forEach(function (element) {
                            (element as HTMLLIElement).classList.remove("active")
                        });

                        if (parent.classList.contains("active")) {
                            parent.classList.remove("active");
                        } else {
                            parent.classList.add("active");
                            (Array.from(sidebar_submenu)[index] as HTMLDivElement).classList.add("show");
                        }
                    })
                })
        });
    }, [])

    const role_branches = (role: string) => {
        return Utils.format_accessible_office(branchesUsers.branches, user.roles.user_roles, role)
    }

    const initialise_data = () => {
        setLoading({text: "Initialising data, please wait", show: true,})
        Req.initialise_system()
            .then((response) => {
                if (response.data.hasOwnProperty("code") && response.data.code === 1) {
                    setLoading({text: "", show: false})
                    setAccounts(response.data.accounts)
                    setLoanPortfolio(response.data.portfolios)
                    setBranchesUsers({branches: response.data.branches, users: response.data.users})
                } else {
                    setLoading({
                        ...loading, reload: initialise_data,
                        error: "Could not initialise data, check your internet connection and then reload"
                    })
                }
            })
            .catch(() => {
                setLoading({
                    ...loading, reload: initialise_data,
                    error: "Could not initialise data, check your internet connection and then reload"
                })
            })
    }

    const read_storage = () => {
        localforage
            .getItem('fcm')
            .then((value: any) => {
                if (value !== null && typeof value === "string") {
                    console.log(JSON.parse(value))
                }
            })
    }

    React.useEffect(() => {
        const menus = document.getElementsByClassName("sidebar_submenu")
        for (let menu_index = 0; menu_index < menus.length; menu_index++) {
            const spans = menus[menu_index].getElementsByClassName("menu_item")
            for (let span_index = 0; span_index < spans.length; span_index++) {
                const span = (spans[span_index] as HTMLSpanElement)
                if (span.classList.contains("active")) {
                    span.classList.remove("active")
                }
            }
        }
        const span = document.getElementById(page.name)
        if (span !== null && span instanceof HTMLSpanElement) {
            span.classList.add("active")
        }
    }, [page])

    React.useEffect(() => {
        if (user.staff.user_id > 0) {
            initialise_data()
            read_storage()
        }
    }, [user.staff.user_id])

    React.useEffect(() => {
        initialise_data()
    }, [])

    const pdf_daily_summary = () => {
        setLoading({text: 'Generating PDF, please wait', show: true})
        Req.pdf_daily_summary({})
            .then((response) => {
                setLoading({...loading, show: false})
                if (response.data.hasOwnProperty("code") && response.data.code === 1) {
                    setShowReader({display: true, source: Req.BASE_URL + response.data.pdf})
                }
            })
            .catch(() => {
                setLoading({...loading, show: false})
            })
    }

    const logout_user = () => {
        setConfirm({
            cancelButton: "Cancel", confirmButton: "Logout", content: "Are you sure you want to logout?", open: true,
            onCancel: () => {
                setConfirm({...confirm, open: false})
            }, onConfirm: () => {
                setConfirm({...confirm, open: false})

                setLoading({text: 'Logging out, please wait', show: true})
                Req.logout_user()
                    .then((response) => {
                        setLoading({...loading, show: false})
                        if (response.data.hasOwnProperty("code")) {
                            if (response.data.code === 1) {
                                dispatch(logoutUser())
                            }
                        } else {
                            ToastsStore.error("Could not logout, please retry")
                        }
                    })
                    .catch(() => {
                        setLoading({...loading, show: false})
                        ToastsStore.error("Could not logout, please retry")
                    })
            },
        })
    }

    /*notifications*/
    const notifications_other = () => {
        return 0
    }

    const notifications_due_loans = () => {
        return 0
    }

    const notifications_payments = () => {
        return 0
    }

    const notifications_payments_made = () => {
        return 0
    }

    const notifications_actions = () => {
        return 0
    }

    return (
        <>
            <Loading show={loading.show} text={loading.text} hide={() => setLoading({...loading, show: false})}/>
            <PDFReader source={showReader.source} open={showReader.display}
                       close={() => setShowReader({...showReader, display: false})}/>
            <Confirm open={confirm.open} onCancel={confirm.onCancel} onConfirm={confirm.onConfirm} cancelButton={confirm.cancelButton}
                     confirmButton={confirm.confirmButton} size='mini' content={confirm.content}/>

            <div ref={page_wrapper} className="page_wrapper toggled">
                <div ref={show_sidebar} className="show_sidebar">
                    <i className="material-icons-sharp">menu</i>
                </div>

                <nav ref={sidebar} className="sidebar_wrapper">
                    <div className="sidebar_content">
                        <div className="sidebar_brand">
                            <div className="brand_name">{company === undefined ? "Express Teller Solutions" : company.name}</div>
                            <div className="close_sidebar" ref={close_sidebar}>
                                <i className="material-icons-sharp">close</i>
                            </div>
                        </div>

                        <div className="sidebar_header">
                            <div className="user_pic">
                                <img src={avatar} alt="User Avatar"/>
                            </div>
                            <div className="user_info">
                                <span className="branch_name">{user.staff.office_name}</span>
                                <span className="user_name">{user.staff.first_name} <strong>{user.staff.last_name}</strong></span>
                                <span className="user_status">
                                    <i className="material-icons-sharp">circle</i>
                                    <span className="user_role">{user.staff.user_name}</span>
                                </span>
                            </div>
                        </div>

                        <div className="sidebar_menu">
                            <ul>
                                {/*home management*/}
                                <li onClick={() => setPage({document: <Home/>, title: "Home", name: 'home'})}>
                                    <span className="menu_item">
                                        <i className="material-icons">home</i>
                                        <span id='home'>Home</span>
                                    </span>
                                </li>

                                {/*system administration*/}
                                <li className="sidebar_dropdown">
                                    <span className="menu_item">
                                        <i className="material-icons-sharp">settings</i>
                                        <span>Management</span>
                                    </span>
                                    <div className="sidebar_submenu">
                                        <ul>
                                            {(Rights.has_right(user.roles.user_roles, Rights.create_users)) &&
                                                <li onClick={() => setPage({title: "Staff Management", document: <Users/>, name: 'staff'})}>
                                                    <span className="menu_item" id='staff'>Staff Management</span>
                                                </li>
                                            }
                                            {/*system administration*/}
                                            {
                                                Rights.has_right(user.roles.user_roles, Rights.admin_panel) &&
                                                <>
                                                    <li onClick={() => setPage({title: "Loan Categories", document: <LoanTypes/>, name: 'types'})}>
                                                        <span className="menu_item" id='types'>Loan Categories</span>
                                                    </li>
                                                    <li className="d-none"
                                                        onClick={() => setPage({title: "Legal Documents", document: <LegalDocuments/>, name: 'docs'})}>
                                                        <span className="menu_item" id='docs'>Legal Documents</span>
                                                    </li>
                                                </>
                                            }
                                            {
                                                (user.roles.core_roles?.includes("modify_core_roles")) &&
                                                <li className=""
                                                    onClick={() => setPage({
                                                        title: "System Settings", name: 'settings',
                                                        document: <Settings branchUser={branchesUsers}/>,
                                                    })}>
                                                    <span className="menu_item" id='settings'>App Settings</span>
                                                </li>
                                            }
                                        </ul>
                                    </div>
                                </li>

                                {/*client management*/}
                                <li className="sidebar_dropdown">
                                    <span className="menu_item dropdown_item">
                                        <i className="material-icons-sharp">groups</i>
                                        <span>Client Management</span>
                                    </span>
                                    <div className="sidebar_submenu">
                                        <ul>
                                            {/*client management*/}
                                            {
                                                Rights.has_right(user.roles.user_roles, Rights.create_clients) &&
                                                <>
                                                    <li
                                                        onClick={() =>
                                                            setPage({
                                                                title: "Client Data", name: 'clients',
                                                                document: <Clients
                                                                    accounts={accounts}
                                                                    branches={role_branches(Rights.create_clients)}
                                                                />
                                                            })}>
                                                        <span className="menu_item" id='clients'>Client Data</span>
                                                    </li>
                                                </>
                                            }
                                            {/*client messaging*/}
                                            <li className="d-none"
                                                onClick={() => setPage({
                                                    title: "Client Messaging", document: <Messaging/>, name: 'messaging'
                                                })}>
                                                <span className="menu_item" id='messaging'>Messaging</span>
                                            </li>
                                        </ul>
                                    </div>
                                </li>

                                {/*loan management*/}
                                <li className="sidebar_dropdown">
                                    <span className="menu_item dropdown_item">
                                        <i className="material-icons-sharp">request_quote</i>
                                        <span>Loan Management</span>
                                        {notifications_actions() > 0 && <span className="badge">{notifications_actions()}</span>}
                                    </span>
                                    <div className="sidebar_submenu">
                                        <ul>
                                            {/*Till allocation*/}
                                            <li
                                                onClick={() => setPage({
                                                    title: "Till Allocation", name: 'till_allocation',
                                                    document: <TillAllocations branchUser={branchesUsers} accounts={accounts}/>
                                                })}>
                                                <span className="menu_item" id='till_allocation'>Till Allocation</span>
                                            </li>

                                            {/*cash at hand*/}
                                            <li
                                                onClick={() => setPage({
                                                    document: <TillBalances branchUsers={branchesUsers} accounts={accounts}/>,
                                                    title: "Till Balances", name: 'till_balances'
                                                })}>
                                                <span className="menu_item" id='till_balances'>Till Balances</span>
                                            </li>

                                            {/*create new loan*/}
                                            {
                                                (Rights.has_right(user.roles.user_roles, Rights.create_loan)) &&
                                                <li
                                                    onClick={() => setPage({
                                                        title: "Create Loan", name: 'new_loan',
                                                        document: <CreateLoan accounts={accounts}/>
                                                    })}>
                                                    <span className="menu_item" id='new_loan'>Create Loan</span>
                                                </li>
                                            }
                                            {/*loan actions*/}
                                            {
                                                (Rights.has_right(user.roles.user_roles, Rights.disburse_loan) ||
                                                    Rights.has_right(user.roles.user_roles, Rights.approve_loan) ||
                                                    Rights.has_right(user.roles.user_roles, Rights.verify_loan) ||
                                                    Rights.has_right(user.roles.user_roles, Rights.create_loan)) &&
                                                <li
                                                    onClick={() => setPage({
                                                        title: "Actions Required", name: 'actions',
                                                        document: <LoanActions branchUser={branchesUsers} accounts={accounts}/>
                                                    })}>
                                                    <span className="menu_item" id='actions'>
                                                        Actions Required {notifications_actions() > 0 &&
                                                        <span className="badge">{notifications_actions()}</span>}
                                                    </span>
                                                </li>
                                            }
                                            {/*inspection reports*/}
                                            {
                                                (Rights.has_right(user.roles.user_roles, Rights.loan_inspections)) &&
                                                <li onClick={() => setPage({title: "Loan Inspections", name: 'inspections', document: <InspectionReports/>})}>
                                                    <span className="menu_item" id='inspections'>Loan Inspection</span>
                                                </li>
                                            }
                                        </ul>
                                    </div>
                                </li>

                                {
                                    (Rights.has_right(user.roles.user_roles, Rights.view_loans)) &&
                                    <>
                                        {/*loan information*/}
                                        <li className="sidebar_dropdown">
                                            <span className="menu_item dropdown_item">
                                                <i className="material-icons-sharp">payments</i>
                                                <span>Loan Information</span>
                                            </span>
                                            <div className="sidebar_submenu">
                                                <ul>
                                                    {/*all loans*/}
                                                    <li
                                                        onClick={() => setPage({
                                                            document: <LoanListing
                                                                status={'all'} accounts={accounts}
                                                                branches={role_branches(Rights.view_loans)}/>,
                                                            title: "All Loans", name: 'all_loans'
                                                        })}>
                                                        <span className="menu_item" id='all_loans'>All Loans</span>
                                                    </li>

                                                    {/*active loans*/}
                                                    <li
                                                        onClick={() => setPage({
                                                            document: <LoanListing
                                                                status={'active'} accounts={accounts}
                                                                branches={role_branches(Rights.view_loans)}/>,
                                                            title: "Active Loans", name: 'active_loans'
                                                        })}>
                                                        <span className="menu_item" id='active_loans'>Active Loans</span>
                                                    </li>

                                                    {/*Extended Loans*/}
                                                    <li
                                                        onClick={() => setPage({
                                                            document: <LoanListing
                                                                status={'extended'} accounts={accounts}
                                                                branches={role_branches(Rights.view_loans)}/>,
                                                            title: "Extended Loans", name: 'extended_loans'
                                                        })}>
                                                        <span className="menu_item" id={'extended_loans'}>Extended Loans</span>
                                                    </li>

                                                    {/*complete loans*/}
                                                    <li onClick={() =>
                                                        setPage({
                                                            title: "Complete Loans", name: 'complete_loans',
                                                            document: <CompleteLoans
                                                                accounts={accounts}
                                                                branches={role_branches(Rights.view_loans)}/>
                                                        })}>
                                                        <span className="menu_item" id='complete_loans'>Complete Loans</span>
                                                    </li>

                                                    {/*monthly loans*/}
                                                    <li
                                                        onClick={() => setPage({
                                                            document: <LoanListing
                                                                status={'monthly'} accounts={accounts}
                                                                branches={role_branches(Rights.view_loans)}/>,
                                                            title: "Monthly Loans", name: 'monthly_loans'
                                                        })}>
                                                        <span className="menu_item" id={'monthly_loans'}>Monthly Loans</span>
                                                    </li>

                                                    {/*deleted loans*/}
                                                    <li
                                                        onClick={() => setPage({
                                                            document: <LoanListing
                                                                status={'deleted'} accounts={accounts}
                                                                branches={role_branches(Rights.view_loans)}/>,
                                                            title: "Deleted Loans", name: 'deleted_loans'
                                                        })}>
                                                        <span className="menu_item" id={'deleted_loans'}>Deleted Loans</span>
                                                    </li>

                                                    {/*modified loans*/}
                                                    <li
                                                        onClick={() => setPage({
                                                            document: <LoanListing
                                                                status={'modified'} accounts={accounts}
                                                                branches={role_branches(Rights.view_loans)}/>,
                                                            title: "Modified Loans", name: 'modified_loans'
                                                        })}>
                                                        <span className="menu_item" id={'modified_loans'}>Modified Loans</span>
                                                    </li>

                                                    <li
                                                        onClick={() => {
                                                            setPage({
                                                                document: <PaymentsProjection branches={role_branches(Rights.view_loans)}/>,
                                                                title: 'Payments Projection', name: `payments_projection}`
                                                            })
                                                        }}>
                                                        <span className="menu_item" id={`payments_projection`}>Payments Projection</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </li>

                                        {/*loan portfolio*/}
                                        <li className="sidebar_dropdown">
                                            <span className="menu_item dropdown_item">
                                                <i className="material-icons-sharp">format_list_numbered</i>
                                                <span>Loan Portfolio</span>
                                            </span>
                                            <div className="sidebar_submenu">
                                                <ul>
                                                    <li
                                                        onClick={() => setPage({
                                                            document: <LoansPortfolio
                                                                user_id={user.staff.user_id} accounts={accounts}
                                                                branches={role_branches(Rights.view_loans)}/>,
                                                            title: "User Loan Portfolio", name: 'user_portfolio'
                                                        })}>
                                                        <span className="menu_item" id='user_portfolio'>My Portfolio</span>
                                                    </li>
                                                    {
                                                        loanPortFolio.map((portfolio) =>
                                                            <li key={portfolio.type_name}
                                                                onClick={() => {
                                                                    setPage({
                                                                        document: <LoansPortfolio
                                                                            type_id={portfolio.type_id} accounts={accounts}
                                                                            branches={role_branches(Rights.view_loans)}/>,
                                                                        title: portfolio.type_name,
                                                                        name: `portfolio_${portfolio.type_id}`
                                                                    })
                                                                }}>
                                                                    <span className="menu_item" id={`portfolio_${portfolio.type_id}`}>
                                                                        {portfolio.type_name}
                                                                    </span>
                                                            </li>
                                                        )
                                                    }
                                                </ul>
                                            </div>
                                        </li>

                                        {/*loan payment areas*/}
                                        <li className="sidebar_dropdown">
                                            <span className="menu_item dropdown_item">
                                                <i className="material-icons-sharp">account_balance</i>
                                                <span>Due Loans</span>
                                            </span>
                                            <div className="sidebar_submenu">
                                                <ul>
                                                    {
                                                        [
                                                            {period: -1, name: 'Custom overdue period'},
                                                            {period: -7, name: 'Due in 1 weeks time'},
                                                            {period: 0, name: "Due Today"},
                                                            {period: 7, name: "1 week overdue"},
                                                            {period: 14, name: "2 weeks overdue"},
                                                            {period: 28, name: "4 weeks overdue"},
                                                            {period: 90, name: "Over 4 weeks overdue"},
                                                            {period: 30, name: "Over 3 months overdue"},
                                                        ].map((due) =>
                                                            <li key={due.period}
                                                                onClick={() => {
                                                                    setPage({
                                                                        document: <DueLoans
                                                                            branches={role_branches(Rights.view_loans)}
                                                                            period={due.period as due_periods} accounts={accounts}/>,
                                                                        title: due.name, name: `due_${due.period}`
                                                                    })
                                                                }}>
                                                                <span className="menu_item" id={`due_${due.period}`}>{due.name}</span>
                                                            </li>
                                                        )
                                                    }
                                                </ul>
                                            </div>
                                        </li>

                                        {/*expected payments*/}
                                        <li className="sidebar_dropdown">
                                            <span className="menu_item dropdown_item">
                                                <i className="material-icons-sharp">credit_card_off</i>
                                                <span>Payment Arrears</span>
                                            </span>
                                            <div className="sidebar_submenu">
                                                <ul>
                                                    {
                                                        [
                                                            {period: -1, name: 'Custom arrears range'},
                                                            {period: -7, name: 'In 1 weeks time'},
                                                            {period: 0, name: "Today's Payments"},
                                                            {period: 7, name: "Less than 1 week arrears"},
                                                            {period: 14, name: "Less than 2 weeks arrears"},
                                                            {period: 28, name: "Less than 4 weeks arrears"},
                                                            {period: 30, name: "Over 4 weeks arrears"},
                                                        ].map((arrears) =>
                                                            <li key={arrears.period}
                                                                onClick={() => {
                                                                    setPage({
                                                                        document: <PaymentArrears
                                                                            branches={role_branches(Rights.view_loans)}
                                                                            accounts={accounts} period={arrears.period as arrears_periods}/>,
                                                                        title: arrears.name, name: `payments_${arrears.period}`
                                                                    })
                                                                }}>
                                                                <span className="menu_item" id={`payments_${arrears.period}`}>{arrears.name}</span>
                                                            </li>
                                                        )
                                                    }
                                                </ul>
                                            </div>
                                        </li>
                                    </>
                                }

                                {/*accounting section*/}
                                <li className="sidebar_dropdown">
                                    <span className="menu_item dropdown_item">
                                        <i className="material-icons-sharp">account_tree</i>
                                        <span>Accounting Section</span>
                                    </span>
                                    <div className="sidebar_submenu">
                                        <ul>
                                            {
                                                Rights.has_right(user.roles.user_roles, Rights.bank_deposits) &&
                                                <>
                                                    <li
                                                        onClick={() => setPage({
                                                            document: <AccountReconciliations accounts={accounts}/>,
                                                            title: "Account Reconciliations", name: 'bank_deposits'
                                                        })}>
                                                        <span className="menu_item" id="bank_deposits">Accounts Reconciliations</span>
                                                    </li>
                                                    <li
                                                        onClick={() => setPage({
                                                            document: <CashTransfers
                                                                accounts={accounts} branchUser={branchesUsers}
                                                                branches={role_branches(Rights.bank_deposits)}/>,
                                                            title: "Bank Deposits", name: 'bank_deposits'
                                                        })}>
                                                        <span className="menu_item" id="bank_deposits">Accounts Transfers</span>
                                                    </li>
                                                    <li
                                                        onClick={() => setPage({
                                                            document: <DirectorDrawings accounts={accounts} branchUser={branchesUsers}/>,
                                                            title: "Director Drawings", name: 'director_drawings'
                                                        })}>
                                                        <span className="menu_item" id="director_drawings">Director Drawings</span>
                                                    </li>
                                                </>
                                            }

                                            <li
                                                onClick={() => setPage({
                                                    document: <ExpenseManagement/>, title: "Expense Management", name: 'expense_management'
                                                })}>
                                                <span className="menu_item" id='expense_management'>Expense Management</span>
                                            </li>

                                            {
                                                Rights.has_right(user.roles.user_roles, Rights.salary_management) &&
                                                <li onClick={() =>
                                                    setPage({
                                                        document: <SalaryManagement/>,
                                                        title: "Salary Management", name: 'salary_management'
                                                    })}>
                                                    <span className="menu_item" id='salary_management'>Salary Management</span>
                                                </li>
                                            }
                                            {
                                                Rights.has_right(user.roles.user_roles, Rights.creditor_management) &&
                                                <li
                                                    onClick={() => setPage({
                                                        document: <SystemCreditors accounts={accounts}/>,
                                                        title: "Creditors & Bank Loans", name: 'creditors'
                                                    })}>
                                                    <span className="menu_item" id='creditors'>Creditors & Bank Loans</span>
                                                </li>
                                            }
                                        </ul>
                                    </div>
                                </li>

                                {
                                    user.roles.report_rights.length > 0 &&
                                    <li
                                        onClick={() => setPage({
                                            document: <SystemReports accounts={accounts}/>,
                                            title: "System Reports", name: 'reports'
                                        })}>
                                        <span className="menu_item">
                                            <i className="material-icons" id='reports'>picture_as_pdf</i><span>System Reports</span>
                                        </span>
                                    </li>
                                }

                            </ul>
                        </div>
                    </div>

                    <div className="sidebar_footer">
                        <span className="footer_item" onClick={logout_user}>
                            <i className="material-icons-sharp">power_settings_new</i>
                        </span>
                        {
                            notifications_other() > 0 &&
                            <span className="footer_item">
                                <i className="material-icons-sharp">notifications</i>
                                <span className="badge notification">{notifications_other()}</span>
                            </span>
                        }

                        {
                            notifications_due_loans() > 0 &&
                            <span className="footer_item">
                                <i className="material-icons-sharp">running_with_errors</i>
                                <span className="badge notification">{notifications_due_loans()}</span>
                            </span>
                        }

                        {
                            notifications_payments() > 0 &&
                            <span className="footer_item">
                                <i className="material-icons-sharp">receipt_long</i>
                                <span className="badge notification">{notifications_payments()}</span>
                            </span>
                        }
                        {
                            notifications_payments_made() > 0 &&
                            <span className="footer_item">
                                <i className="material-icons-sharp">alarm_on</i>
                                <span className="badge notification">{notifications_payments_made()}</span>
                            </span>
                        }
                    </div>
                </nav>

                <main className="page_content">
                    <div>
                        {page.document}
                    </div>
                </main>
            </div>
        </>
    )
}
