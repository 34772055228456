import {Button, Form, Modal, Table, TextArea} from "semantic-ui-react";
import Utils from "../../../../../utils/Utils";
import {Loading, MyInput, MyLabel} from "../../../../../utils/Components";
import React from "react";
import {LoanFee, LoanInfo, LoanProps} from "../../ShowLoan";
import {useAppSelector} from "../../../../../store/hooks";
import {getStaff} from "../../../../../store/slices/userSlice";
import {ToastsStore} from "react-toasts";
import {Req} from "../../../../../utils/Req";

export default function ChargesAvailable(params: { loanInfo: LoanInfo, loanProps: LoanProps, setLoanInfo: (loanInfo: LoanInfo) => void }) {
    const user = useAppSelector(getStaff)
    const [loading, setLoading] = React.useState({show: false, message: ""})
    const [modify, setModify] = React.useState({show: false, reason: ""})
    const [charges, setCharges] = React.useState(params.loanInfo.charges)

    const upload_modified_loan_charges = () => {
        let has_error = false
        if (modify.reason.trim().length < 10) {
            ToastsStore.error("Enter valid reason for modifying loan changes")
            has_error = true
        } else {
            const charges = params.loanInfo.charges
            for (let index = 0; index < charges.length && !has_error; index++) {
                if (!Utils.is_valid_number(charges[index].charge_amount.toString()) || parseFloat(charges[index].charge_amount.toString()) < 0) {
                    ToastsStore.error(`Enter a valid charge for ${charges[index].fee_name}`)
                    has_error = true
                }
            }
        }

        if (has_error) {
            return;
        }

        setModify({...modify, show: false})

        const charges: { charge_id: number, charge_type: 'Flat' | 'Percentage', charge_value: number, charge_amount: number }[] =
            params.loanInfo.charges
                .filter((aCharge) => aCharge.date_paid === "")
                .map((aCharge) => ({
                    charge_type: aCharge.charge_type,
                    charge_id: aCharge.charge_id,
                    charge_amount: parseFloat(aCharge.charge_amount.toString()),
                    charge_value: aCharge.charge_value
                }))

        setLoading({message: "Modifying loan charges, please wait", show: true})

        Req.update_loan_charges({loan_id: params.loanInfo.loan_id, reason: modify.reason.trim(), charges: JSON.stringify(charges)})
            .then(response => {
                setLoading({message: "", show: false})
                if (response.data.hasOwnProperty("code")) {
                    if (response.data.code === 1) {
                        ToastsStore.success("Loan charges have been saved successfully")
                    } else if (response.data.code === 2) {
                        ToastsStore.error("Your account has not been opened up for transactions")
                    } else if (response.data.code === 3) {
                        ToastsStore.error("Your transactions for today have been closed")
                    }
                } else {
                    ToastsStore.error("Error while saving loan, please retry")
                }
            })
            .catch(() => {
                setLoading({message: "", show: false})
                ToastsStore.error("Error while saving loan, please retry")
            })
    }

    const calculate_loan_fee = (charge_id: number): { charge_type: "Flat" | "Percentage", charge_value: number, charge_amount: number | string } => {
        const initialFee = {charge_type: "Flat" as "Flat", charge_value: 0, fee_name: "", charge_amount: "-"}
        if (params.loanInfo.loan_type !== null) {
            const principal = Utils.strip_commas(params.loanInfo.loan_principal)
            if (!Utils.is_valid_number(principal)) {
                return initialFee
            }

            let fees = params.loanProps.charges.filter((charge) => charge.charge_id === charge_id)[0]?.fees
                .filter((fee) => parseFloat(principal) >= fee.min_value)
                .sort((a, b) => b.min_value - a.min_value)
            if (fees.length > 0) {
                return {
                    charge_type: fees[0].charge_type, charge_value: fees[0].charge_value,
                    charge_amount: fees[0].charge_type === "Flat" ? fees[0].charge_value : ((fees[0].charge_value * parseFloat(principal)) / 100)
                }
            }
        }
        return {...initialFee, charge_amount: "N/A"}
    }


    React.useEffect(() => {
        setCharges(
            charges.map((aCharge) => {
                const chargeFee = calculate_loan_fee(aCharge.charge_id)
                return {...aCharge, charge_amount: aCharge.charge_amount === "N/A" ? "N/A" : chargeFee.charge_amount, charge_value: chargeFee.charge_value}
            })
        )
    }, [params.loanInfo.loan_principal])

    React.useEffect(() => {
        if (params.loanInfo.loan_type === null) {
            setCharges([])
        } else {
            setCharges(
                params.loanProps.charges.filter((charge) => charge.type_id === params.loanInfo.loan_type?.type_id)
                    .map((charge) => {
                        const chargeFee = calculate_loan_fee(charge.charge_id)
                        const aCharge = params.loanInfo.charges.filter((_charge) => _charge.charge_id === charge.charge_id)[0] ?? undefined
                        return {
                            charge_id: charge.charge_id,
                            charge_type: chargeFee.charge_type,
                            date_paid: aCharge?.date_paid ?? "",
                            charge_value: chargeFee.charge_value,
                            cashier_name: aCharge?.cashier_name ?? "",
                            charge_amount: aCharge?.charge_amount ?? chargeFee.charge_amount,
                            fee_name: params.loanProps.charges.filter((lCharge) => lCharge.charge_id === charge.charge_id)[0]?.fee_name
                        }
                    })
            )
        }
    }, [params.loanInfo.loan_type])

    React.useEffect(() => {
        params.setLoanInfo({...params.loanInfo, charges: charges.filter((charge) => charge.charge_amount !== "N/A")})
    }, [charges])


    React.useEffect(() => {

    }, [params.loanInfo.loan_type, params.loanInfo.loan_principal])


    const isActive = () => {
        if (params.loanInfo.loan_status === "pending") {
            return true
        } else if (["rejected", "accepted", "verified"].includes(params.loanInfo.loan_status)) {
            return params.loanInfo.others.loan_statuses.filter((status) => status.status_name === "created")[0]?.user_id === user.staff.user_id;
        } else if (params.loanInfo.loan_status === 'active' && user.roles.core_roles?.includes("modify_loan_charges")) {
            return true
        }

        return false
    }

    const isVisible = () => {
        return params.loanInfo.loan_status === 'active' && user.roles.core_roles?.includes("modify_loan_charges")
    }

    return (
        <>
            <Loading show={loading.show} text={loading.message} hide={() => setLoading({...loading, show: false})}/>

            <Modal open={modify.show} basic size='mini' centered={false}>
                <div className="modal_div">
                    <div className="form_header">Modify Loan Charges</div>

                    <div className="form_content">
                        <MyLabel title="Reason for modifying loan charges"/>
                        <Form>
                            <TextArea
                                placeholder='Enter reason for deleting loan' rows={3} value={modify.reason}
                                onChange={((_event, data) => setModify({...modify, reason: data.value as string}))}/>
                        </Form>
                    </div>

                    <div className="form_footer mt-3">
                        <div className="row m-0">
                            <div className="col-6 pl-0 pr-1">
                                <Button
                                    negative onClick={() => setModify({...modify, show: false})}
                                    content="Close Window" size='mini' fluid icon='close' labelPosition={"left"}/>
                            </div>
                            <div className="col-6 pl-1 pr-0">
                                <Button
                                    positive onClick={upload_modified_loan_charges} content="Modify Loan" size='mini'
                                    fluid icon="trash" labelPosition={"left"}/>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>

            <div style={{height: '50px', marginBottom: '1px'}}>
                <MyLabel title={"Charges available for loan"}/>
            </div>

            <div className="table_container" style={{height: 'auto'}}>
                <Table celled striped compact size='small' inverted color='grey' selectable>
                    <Table.Header fullWidth>
                        <Table.Row>
                            <Table.HeaderCell style={{width: '70px'}} textAlign="center">No.</Table.HeaderCell>
                            <Table.HeaderCell style={{width: '140px'}}>Charge Name</Table.HeaderCell>
                            <Table.HeaderCell style={{width: '100px'}}>Charge Value</Table.HeaderCell>
                            <Table.HeaderCell style={{width: '100px'}}>Amount Charged</Table.HeaderCell>
                            <Table.HeaderCell style={{width: '140px'}}>Received By</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {
                            charges
                                .map((charge) =>
                                    <Table.Row key={charge.charge_id}>
                                        <Table.Cell style={{width: '70px'}} textAlign="center">
                                            <Button
                                                primary size='mini' icon='add' compact
                                                disabled={
                                                    charge.charge_amount !== "N/A" ||
                                                    (params.loanInfo.loan_status === 'active' && !user.roles.core_roles?.includes("modify_loan_charges"))
                                                }
                                                onClick={() => {
                                                    setCharges(
                                                        charges.map((aCharge) => aCharge.charge_id === charge.charge_id ?
                                                            {...aCharge, charge_amount: aCharge.charge_value} : aCharge)
                                                    )
                                                }}/>

                                            <Button
                                                primary size='mini' icon='trash' compact
                                                disabled={
                                                    charge.date_paid !== "" ||
                                                    (params.loanInfo.loan_status === 'active' && !user.roles.core_roles?.includes("modify_loan_charges"))
                                                }
                                                onClick={() => {
                                                    setCharges(
                                                        charges.map((aCharge) => aCharge.charge_id === charge.charge_id ?
                                                            {...aCharge, charge_amount: "N/A"} : aCharge)
                                                    )
                                                }}/>
                                        </Table.Cell>

                                        <Table.Cell style={{width: '140px'}}>{charge.fee_name}</Table.Cell>

                                        <Table.Cell style={{width: '100px'}}>
                                            {
                                                Utils.comma_number(
                                                    charge.charge_type === "Flat" ? charge.charge_value :
                                                        (Utils.is_valid_number(Utils.strip_commas(params.loanInfo.loan_principal)) ?
                                                                (charge.charge_value * (parseFloat(Utils.strip_commas(params.loanInfo.loan_principal)) / 100)) : 0

                                                        ))
                                            }
                                        </Table.Cell>

                                        <Table.Cell style={{width: '100px'}}>
                                            <MyInput
                                                placeholder="Enter charge" name="" className="mb-0" disabled={!isActive()}
                                                value={Utils.comma_number(charge.charge_amount)}
                                                change={(_text, value) => {
                                                    setCharges(
                                                        charges.map((_charge) => {
                                                            if (_charge.charge_id === charge.charge_id) {
                                                                return {..._charge, charge_amount: Utils.strip_commas(value)}
                                                            } else {
                                                                return {..._charge}
                                                            }
                                                        })
                                                    )
                                                }}/>
                                        </Table.Cell>

                                        <Table.Cell style={{width: '140px'}}>{charge.cashier_name}</Table.Cell>
                                    </Table.Row>
                                )
                        }
                    </Table.Body>
                </Table>

                {
                    isVisible() &&
                    <div className={'row mx-0 p-2'}>
                        <div className='col-12 col-md-6 offset-md-3 px-0'>
                            <Button
                                positive fluid size='tiny' icon="save" content={"Update Charges"} labelPosition={"left"}
                                onClick={() => setModify({reason: "", show: true})}/>
                        </div>
                    </div>
                }

            </div>
        </>
    )
}
