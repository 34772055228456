import {Button, Confirm, Modal, Table} from "semantic-ui-react";
import Utils, {ConfirmAction, initial_confirm} from "../../../../utils/Utils";
import React from "react";
import {RcSource} from "../AccountReconciliations";
import {Account} from "../../../../utils/Models";
import {MySelect, select_type} from "../../../../utils/Components";

export interface RcExpense {
    account_id: number
    account: string
    amount: number
    date: string
    desc: string
    id: number
    office: string
    user: string
}

export default function RcExpenses(params: {
    expenses: RcExpense[], sources: RcSource[], accounts: Account[], journalAccounts: Account[]
}) {
    const initial_expense: RcExpense = {account: "", account_id: 0, amount: 0, date: "", desc: "", id: 0, office: "", user: ""}
    const delete_expense = (expense_id: number) => {
        setConfirm({
            ...confirm, open: true, content: 'Are you sure you want to delete this expense?',
            onConfirm: () => {
                setConfirm({...confirm, 'open': false})
            },
            onCancel: () => setConfirm({...confirm, 'open': false})
        })
    }

    const [confirm, setConfirm] = React.useState<ConfirmAction>(initial_confirm)
    const update_expense = () => {

    }

    const [edit, setEdit] = React.useState({show: false, expense: initial_expense})

    const handle_change = (name: string, value: string | number) => {
        setEdit({...edit, expense: {...edit.expense, [name]: value}})
    }

    return (
        <>
            <Confirm
                open={confirm.open} onCancel={confirm.onCancel} onConfirm={confirm.onConfirm} size='mini'
                cancelButton={confirm.cancelButton} confirmButton={confirm.confirmButton} content={confirm.content}/>

            <Modal open={edit.show} basic size='mini' centered={false}>
                <div className="modal_div modal_form">
                    <div className="form_content">
                        <MySelect
                            change={(value: select_type) => handle_change('account_id', (value as number))}
                            title="Select expense account name" value={edit.expense.account_id}
                            options={[
                                {text: "Select an expense account", value: 0},
                                ...params.journalAccounts.map((aAccount) => ({text: aAccount.account_name, value: aAccount.account_id}))
                            ]}/>
                    </div>

                    <div className="form_footer">
                        <div className="row m-0">
                            <div className="col-6 pl-0 pr-1">
                                <Button
                                    negative onClick={() => setEdit({...edit, show: false})}
                                    content="Close Window" size='mini' fluid icon='close' labelPosition={"left"}/>
                            </div>
                            <div className="col-6 pl-1 pr-0">
                                <Button
                                    positive onClick={update_expense} content="Update Expense"
                                    size='mini' fluid icon="save" labelPosition={"left"}/>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>

            <div className="table_container">
                <Table celled striped compact size='small' inverted color='grey' selectable>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell style={{width: '80px'}} textAlign="center"/>
                            <Table.HeaderCell style={{width: '50px'}} textAlign="center">No.</Table.HeaderCell>
                            <Table.HeaderCell style={{width: '120px'}}>Branch Name</Table.HeaderCell>
                            <Table.HeaderCell style={{width: '130px'}}>Created By</Table.HeaderCell>
                            <Table.HeaderCell style={{width: '150px'}}>Expense Name</Table.HeaderCell>
                            <Table.HeaderCell style={{width: '100px'}}>Amount</Table.HeaderCell>
                            <Table.HeaderCell style={{width: '170px'}}>Date</Table.HeaderCell>
                            <Table.HeaderCell style={{width: '400px'}}>Description</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {
                            params.expenses.map((expense, index) =>
                                <Table.Row key={expense.id}>
                                    <Table.Cell style={{width: '80px'}} textAlign="center">
                                        <Button
                                            primary size='mini' icon='edit' compact
                                            onClick={() => setEdit({show: true, expense: expense})}/>
                                        <Button
                                            primary size='mini' icon='trash alternate' compact
                                            onClick={() => delete_expense(expense.id)}/>
                                    </Table.Cell>
                                    <Table.Cell style={{width: '50px'}} textAlign="center">{Utils.row_number(index)}</Table.Cell>
                                    <Table.Cell style={{width: '120px'}}>{expense.office}</Table.Cell>
                                    <Table.Cell style={{width: '130px'}}>{expense.user}</Table.Cell>
                                    <Table.Cell style={{width: '150px'}}>{expense.account}</Table.Cell>
                                    <Table.Cell style={{width: '100px'}}>{Utils.comma_number(expense.amount)}</Table.Cell>
                                    <Table.Cell style={{width: '170px'}}>{Utils.localise_date_time(expense.date)}</Table.Cell>
                                    <Table.Cell style={{width: '400px'}}>{expense.desc}</Table.Cell>
                                </Table.Row>
                            )
                        }
                    </Table.Body>
                </Table>
            </div>
        </>
    )
}
