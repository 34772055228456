import React from "react"
import Utils from "../../../../utils/Utils"
import {MyInput, MyLabel, MySelect, select_type} from "../../../../utils/Components"
import {Req} from "../../../../utils/Req"
import {LoanInfo, LoanProps} from "../ShowLoan"
import CreateClient, {SystemClient} from "../../../clients/CreateClient";
import PhoneInput from "react-phone-input-2";
import {ToastsStore} from "react-toasts";
import {Account} from "../../../../utils/Models";

export interface Guarantor {
    client_id: number
    relationship: string
    monthly_income: string
    main_contact: string
    other_contact: string
}

export const initial_guarantor: Guarantor = {
    client_id: 0, other_contact: "", main_contact: "", monthly_income: "", relationship: ""
}

export interface ClientInfo {
    main_contact: string
    loans_taken: number
    cash_due: number
}

export const initial_client_info: ClientInfo = {cash_due: 0, loans_taken: 0, main_contact: ""}

export function LoanData(params: {
    loanProps: LoanProps, loanInfo: LoanInfo, setLoanInfo: (loanInfo: LoanInfo) => void,
    setClients: (clients: SystemClient[]) => void, accounts: Account[]
}) {

    const [addClient, showAddClient] = React.useState(false)
    const [addGuarantor, showAddGuarantor] = React.useState<{ show: boolean, index: 0 | 1 }>({show: false, index: 0})

    const load_guarantor_info = (param: { index: 1 | 0, client_id: number }) => {
        if (param.client_id === 0) {
            params.setLoanInfo({
                ...params.loanInfo, guarantors: params.loanInfo.guarantors
                    .map((guarantor, index) => index === param.index ? initial_guarantor : guarantor)
            })
        } else {
            Req.get_guarantor_loan_info({client_id: param.client_id})
                .then(response => {
                    if (response.data.hasOwnProperty("code") && response.data.code === 1) {
                        params.setLoanInfo({
                            ...params.loanInfo, guarantors: params.loanInfo.guarantors
                                .map((guarantor, index) => index !== param.index ? guarantor : {
                                    client_id: param.client_id,
                                    other_contact: response.data.info.other_contact,
                                    main_contact: response.data.info.main_contact,
                                    monthly_income: guarantor.monthly_income,
                                    relationship: guarantor.relationship
                                })
                        })
                    } else {
                        ToastsStore.error("Could not load guarantor data")
                    }
                })
                .catch(() => {
                    params.setLoanInfo({
                        ...params.loanInfo, guarantors: params.loanInfo.guarantors
                            .map((guarantor, index) => index !== param.index ? guarantor : initial_guarantor)
                    })
                    ToastsStore.error("Could not load guarantor data")
                })
        }
    }

    const handle_guarantor = (param: { index: 1 | 0, name: string, value: string }) => {
        params.setLoanInfo({
            ...params.loanInfo, guarantors: params.loanInfo.guarantors
                .map((guarantor, index) => index !== param.index ?
                    guarantor : {...guarantor, [param.name]: param.value})
        })
    }

    const handle_client_change = (client_id: number) => {
        Req.get_client_loan_info({client_id: client_id})
            .then(response => {
                const client_info: ClientInfo = {
                    cash_due: response.data.cash_due,
                    loans_taken: response.data.loans_taken,
                    main_contact: response.data.main_contact
                }
                params.setLoanInfo({
                    ...params.loanInfo, client_id: client_id, client_info: client_info
                })
            })
    }

    const [loadingClients, setLoadingClients] = React.useState(false)

    const query_clients = (query: string) => {
        setLoadingClients(true)
        let clients = Array<number>()
        if (params.loanInfo.client_id > 0) {
            clients = [...clients, params.loanInfo.client_id]
        }
        if (params.loanInfo.guarantors[0].client_id > 0) {
            clients = [...clients, params.loanInfo.guarantors[0].client_id]
        }
        if (params.loanInfo.guarantors[1].client_id > 0) {
            clients = [...clients, params.loanInfo.guarantors[1].client_id]
        }

        Req.search_clients({clients: JSON.stringify(clients), query: query})
            .then((response) => {
                setLoadingClients(false)
                if (response.data.hasOwnProperty("code")) {
                    params.setClients(response.data.clients)
                }
            })
            .catch(() => {
                setLoadingClients(false)
            })
    }

    const handle_broker = (name: string, value: string) => {
        params.setLoanInfo({...params.loanInfo, broker: {...params.loanInfo.broker, [name]: value}})
    }

    React.useEffect(() => {

    }, [params.loanInfo])

    return (
        <>
            <CreateClient
                show={addClient} accounts={params.accounts}
                close={(_param) => {
                    showAddClient(false)
                    if (_param !== undefined) {
                        handle_client_change(_param.client.client_id)
                        params.setClients([...params.loanProps.clients, _param.systemClient])
                    }
                }}/>

            <CreateClient
                show={addGuarantor.show} accounts={params.accounts}
                close={(_param) => {
                    if (_param !== undefined) {
                        params.setClients([...params.loanProps.clients, _param.systemClient])
                        params.setLoanInfo({
                            ...params.loanInfo, guarantors: params.loanInfo.guarantors
                                .map((guarantor, index) => index !== addGuarantor.index ? guarantor : {
                                    client_id: _param.client.client_id,
                                    monthly_income: guarantor.monthly_income,
                                    relationship: guarantor.relationship,
                                    other_contact: _param.client.other_contact,
                                    main_contact: _param.client.main_contact,
                                })
                        })
                    }
                    showAddGuarantor({show: false, index: 0})
                }}/>

            <div className="row mx-0">
                {/*Loan Applicant*/}
                <div className="col-12 col-md-3 loan_form">
                    <div>
                        <MySelect
                            change={(value: select_type) => {
                                const client_id = parseInt(value as string)
                                if (client_id === 0) {
                                    params.setLoanInfo({...params.loanInfo, client_info: initial_client_info, client_id: client_id})
                                } else if (client_id === -1) {
                                    showAddClient(true)
                                } else {
                                    handle_client_change(client_id)
                                }
                            }}
                            search={(query) => {
                                if (query.trim().length >= 3) {
                                    query_clients(query)
                                }
                            }}
                            title="Loan Applicant" name="" value={params.loanInfo.client_id} loading={loadingClients}
                            options={[
                                {text: "Create new applicant", value: -1}, {text: "Select an applicant", value: 0},
                                ...params.loanProps.clients.map((client) =>
                                    ({text: client.client_name, value: client.client_id}))
                            ]}/>

                        <MyInput
                            placeholder="Email Address" title="Email Address" name="application_fees" disabled={true}
                            value={params.loanInfo.client_info.main_contact}/>

                        <MyInput
                            placeholder="Mobile Contact" title="Mobile Contact" name="main_contact" disabled={true}
                            value={params.loanInfo.client_info.main_contact}/>

                        <MyInput
                            placeholder="Total loans taken" title="Loans Taken"
                            name="loans_taken" disabled={true} value={params.loanInfo.client_info.loans_taken.toString()}/>

                        <MyInput
                            placeholder="Money client owes" title="Due Cash" style={{marginBottom: '0px'}}
                            name="cash_due" disabled={true} value={Utils.comma_number(params.loanInfo.client_info.cash_due)}/>
                    </div>
                </div>

                {
                    [0, 1]
                        .map((index) =>
                            <div className="col-12 col-md-3 loan_form">
                                <div>
                                    <MySelect
                                        change={(value: select_type) => {
                                            const client_id = parseInt(value as string)
                                            if (client_id === -1) {
                                                showAddGuarantor({index: index as 1 | 0, show: true})
                                            } else {
                                                load_guarantor_info({client_id: client_id, index: index as 1 | 0});
                                            }
                                        }}
                                        search={(query) => {
                                            if (query.trim().length >= 3) {
                                                query_clients(query)
                                            }
                                        }}
                                        disabled={loadingClients} title="Guarantor Name" name="" loading={loadingClients}
                                        value={params.loanInfo.guarantors[index].client_id}
                                        options={[
                                            {text: "Create new loan guarantor", value: -1}, {text: "Loan has no guarantor", value: 0},
                                            ...params.loanProps.clients.map((client) => ({text: client.client_name, value: client.client_id}))
                                        ]}/>

                                    <MySelect
                                        change={(value: select_type) => {
                                            handle_guarantor({index: index as 1 | 0, name: "relationship", value: (value as string)})
                                        }}
                                        title="Relationship with Guarantor" name="" value={params.loanInfo.guarantors[index].relationship}
                                        options={Utils.guarantor_relationships}/>

                                    <MyInput
                                        placeholder="Monthly income (UGX)" title="Monthly income (UGX)" name="monthly_income"
                                        value={params.loanInfo.guarantors[index].monthly_income}
                                        change={(name, value) => {
                                            handle_guarantor({index: index as 1 | 0, name: "monthly_income", value: (value as string)})
                                        }}/>

                                    <MyInput
                                        placeholder="Guarantor main contact" title="Main Contact"
                                        name="" disabled={true} value={params.loanInfo.guarantors[index].main_contact}/>

                                    <MyInput
                                        placeholder="Guarantor main contact" title="Other Contact" style={{marginBottom: '0px'}}
                                        name="" disabled={true} value={params.loanInfo.guarantors[index].other_contact}/>
                                </div>
                            </div>
                        )
                }

                {/*Loan Broker*/}
                <div className="col-12 col-md-3 loan_form">
                    <div>
                        <MyInput
                            placeholder="Broker Name" title="Broker Name" name="broker_name"
                            value={params.loanInfo.broker.broker_name} change={handle_broker}/>

                        <MyLabel title="Broker Contact"/>
                        <PhoneInput
                            countryCodeEditable={false} containerStyle={{marginBottom: '15px'}} country={'ug'}
                            value={params.loanInfo.broker.broker_contact}
                            onlyCountries={['ug']} onChange={(value) => handle_broker('broker_contact', value)}/>

                        <MyInput
                            placeholder="Broker Commission" title="Broker Commission" name="broker_commission"
                            value={params.loanInfo.broker.broker_commission} change={handle_broker}/>
                    </div>
                </div>
            </div>
        </>
    )
}
