import {ToastsStore} from "react-toasts";
import React from "react";
import {Button, Form, Modal, TextArea} from "semantic-ui-react";
import {Req} from "../../../../../utils/Req";
import {Loading, MyDateInput, MyInput, MyLabel, MySelect, select_type} from "../../../../../utils/Components";
import moment from "moment/moment";
import {LoanInfo} from "../../ShowLoan";
import Utils from "../../../../../utils/Utils";
import {intervals} from "../../../../admin/loans/LoanTypes";

export interface ModifyInfo {
    start_date?: string
    date_disbursed?: string
    loan_principal?: string
    loan_rate?: string
    loan_period?: string
    payment_interval?: intervals,
}

export default function ModifyLoanStructure(params: ModifyInfo & { show: boolean, loan_id: number, close: (loanInfo?: LoanInfo) => void }) {
    const [loading, setLoading] = React.useState({show: false, title: ""})
    const [modify, setModify] = React.useState<ModifyInfo & { reason: string }>({reason: ''})

    const adjust_date = () => {
        const principal = modify.loan_principal === undefined ? undefined : Utils.strip_commas(modify.loan_principal)

        if (principal !== undefined && (!Utils.is_valid_number(principal) || parseFloat(principal) < 50000)) {
            ToastsStore.error("Enter valid loan principal above ugx 50,000")
        } else if (modify.loan_period !== undefined && (!Utils.is_valid_number(modify.loan_period) || parseInt(modify.loan_period) < 1)) {
            ToastsStore.error("Enter a valid loan period")
        } else if (modify.loan_rate !== undefined && (!Utils.is_valid_number(modify.loan_rate) || parseFloat(modify.loan_rate) < 0 || parseFloat(modify.loan_rate) > 100)) {
            ToastsStore.error("Enter a valid loan rate")
        } else if (modify.start_date !== undefined && modify.start_date === "") {
            ToastsStore.error("Select a valid date of first loan installment payment")
        } else if (modify.reason.trim().length < 10) {
            ToastsStore.error("Enter a valid reason for modifying loan start date")
        } else {
            setLoading({show: true, title: "Modifying loan structure, please wait"})
            Req.modify_loan_structure({
                reason: modify.reason,
                loan_id: params.loan_id,
                loan_principal: principal,
                loan_rate: modify.loan_rate,
                start_date: modify.start_date,
                loan_period: modify.loan_period,
                payment_interval: modify.payment_interval
            })
                .then((response) => {
                    setLoading({show: false, title: ""})
                    if (response.data.hasOwnProperty("code")) {
                        if (response.data.code === 1) {
                            ToastsStore.success("Loan structure has been modified successfully")
                            params.close(response.data.loan)
                        } else {
                            ToastsStore.error(response.data.hasOwnProperty("msg") ? response.data['msg'] : "Loan could not be modified, please retry")
                        }
                    } else {
                        ToastsStore.error(response.data.hasOwnProperty("msg") ? response.data['msg'] : "Loan could not be modified, please retry")
                    }
                })
                .catch(() => {
                    setLoading({show: false, title: ""})
                    ToastsStore.error("Loan could not be modified, please retry")
                })
        }
    }

    React.useEffect(() => {
        console.log(params)
        setModify({
            reason: "", date_disbursed: params.date_disbursed, start_date: params.start_date, loan_rate: params.loan_rate,
            loan_principal: params.loan_principal, loan_period: params.loan_period, payment_interval: params.payment_interval
        })
    }, [params.show])

    return (
        <>
            <Loading show={loading.show} text={loading.title}/>

            <Modal open={params.show && params.date_disbursed !== ''} basic size='mini' centered={false}>
                <div className="modal_div">
                    <div className="form_header">Modify Loan Structure</div>

                    <div className="form_content">
                        {
                            (modify.date_disbursed !== undefined && modify.start_date !== undefined) &&
                            <div style={{marginBottom: '15px'}}>
                                <MyDateInput
                                    title="Date of first installment payment" value={modify.start_date} minDate={params.date_disbursed}
                                    maxDate={moment(params.date_disbursed, "YYYY-MM-DD").add('months', 2).format("YYYY-MM-DD")}
                                    name="start_date" placeholder="Select payment start date"
                                    change={(name, value) => setModify({...modify, start_date: value})}/>
                            </div>
                        }

                        {
                            (modify.loan_rate !== undefined) &&
                            <div style={{marginBottom: '15px'}}>
                                <MyInput
                                    placeholder="Enter loan rate" title="Interest Rate (In percentage)" name="loan_rate" className={'mb-0'}
                                    value={modify.loan_rate} change={(_name, value) => setModify({...modify, loan_rate: value})}/>
                            </div>
                        }

                        {
                            (modify.loan_principal !== undefined) &&
                            <div style={{marginBottom: '15px'}}>
                                <MyInput
                                    placeholder="Enter loan principal" title="Loan Principal"
                                    name="loan_principal" className={'mb-0'} value={Utils.comma_input(modify.loan_principal)}
                                    change={(_name, value) => setModify({...modify, loan_principal: value})}/>
                            </div>
                        }

                        {
                            (modify.loan_period !== undefined) &&
                            <div style={{marginBottom: '15px'}}>
                                <MyInput
                                    placeholder="Enter loan period" title="Loan Period (In months)" name="loan_period" className={'mb-0'}
                                    value={modify.loan_period} change={(_name, value) => setModify({...modify, loan_period: value})}/>
                            </div>
                        }

                        {
                            (modify.payment_interval !== undefined) &&
                            <div style={{marginBottom: '15px'}}>
                                <MySelect
                                    change={(value: select_type) => setModify({...modify, payment_interval: value as intervals})}
                                    title="Loan payment Interval" name="payment_interval"
                                    value={modify.payment_interval}
                                    options={
                                        [
                                            {text: "Daily Payment", value: 1}, {text: "One week (7 days)", value: 7},
                                            {text: "Two weeks (14 days)", value: 14}, {text: "One Month", value: 30}
                                        ]
                                    }/>
                            </div>
                        }

                        <MyLabel title="Reason for modifying loan structure"/>
                        <Form>
                            <TextArea
                                placeholder='Enter reason for modifying loan' rows={3} value={modify.reason}
                                onChange={((event, data) => setModify({...modify, reason: data.value as string}))}/>
                        </Form>
                    </div>

                    <div className="form_footer mt-3">
                        <div className="row m-0">
                            <div className="col-6 pl-0 pr-1">
                                <Button negative onClick={() => params.close(undefined)} content="Close Window" size='mini' fluid
                                        icon='close' labelPosition={"left"}/>
                            </div>
                            <div className="col-6 pl-1 pr-0">
                                <Button positive onClick={adjust_date} content="Modify Loan" size='mini' fluid
                                        icon="save" labelPosition={"left"}/>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}
