import React from "react";
import {Button, Modal} from "semantic-ui-react";
import {Req} from "../../../../utils/Req";
import {ToastsStore} from "react-toasts";

export default function ResetPassword(params: { show: boolean, close: () => void, user_id: number, username: string }) {
    const [userData, setUserData] = React.useState({new_password: "", confirm_password: ""}
    )
    const [error, setError] = React.useState("")
    const [loading, setLoading] = React.useState(false)

    React.useEffect(() => {
        setUserData({new_password: '', confirm_password: ""})
    }, [params.show])

    const reset_password = () => {
        setError("")
        if (userData.new_password.length < 6) {
            setError("New password must be at least 6 characters")
        } else if (userData.new_password !== userData.confirm_password) {
            setError("New password and confirmation do not match")
        } else {
            setLoading(true)
            Req.reset_password({new_password: userData.new_password, user_id: params.user_id})
                .then(response => {
                    setLoading(false)
                    if (response.data['code'] === 1) {
                        params.close()
                        ToastsStore.success("Password has been reset successfully")
                    } else {
                        setError(response.data.msg)
                    }
                })
                .catch(() => {
                    setLoading(false)
                    setError("Could not login at the moment, please retry")
                })
        }
    }

    return (
        <Modal size='mini' open={params.show} onClose={() => params.close()} closeOnDocumentClick={true} closeIcon={true}>
            <div className="login_modal">
                <h2>Reset Password</h2> :
                <div className="ui info message show">{"Reset password for " + params.username}</div>

                <div className="ui large form">
                    <div className="ui stacked secondary  segment">
                        {/*new password*/}
                        <div className="field">
                            <div className="ui left icon input">
                                <i className="lock icon"/>
                                <input type="password" placeholder="New password" value={userData.new_password}
                                       onChange={(event) => {
                                           setUserData({...userData, 'new_password': event.target.value})
                                       }}/>
                            </div>
                        </div>

                        {/*confirm password*/}
                        <div className="field">
                            <div className="ui left icon input">
                                <i className="lock icon"/>
                                <input type="password" placeholder="Confirm password" value={userData.confirm_password}
                                       onChange={(event) => {
                                           setUserData({...userData, 'confirm_password': event.target.value})
                                       }}/>
                            </div>
                        </div>

                        <Button className="ui button fluid teal" size='small' loading={loading} disabled={loading}
                                onClick={reset_password}>
                            Reset Password
                        </Button>
                    </div>
                    <div className={`ui error message ${error === "" ? '' : 'show'}`}>{error}</div>
                </div>
            </div>
        </Modal>
    )
}
