import {Req} from "../../../../utils/Req";
import {ToastsStore} from "react-toasts";
import Utils from "../../../../utils/Utils";
import React from "react";
import {LoanInfo} from "../ShowLoan";
import {Loading, MyDateInput, MyLabel} from "../../../../utils/Components";
import {Button, Checkbox, Modal, Radio, Table} from "semantic-ui-react";
import {useAppSelector} from "../../../../store/hooks";
import {getCurrentDate} from "../../../../store/slices/systemSlice";
import moment from "moment";
import {Allocation} from "../../../../utils/Models";
import AllocationTable from "../../../../utils/components/AllocationTable";

interface Transaction {
    type: '+' | '-'
    group: 'principal' | 'charges' | 'account'
    amount: number
    fee_name: string
    optional: 1 | 0
    checked: 1 | 0
}

export default function DisburseLoan(params: {
    loanInfo: LoanInfo, close: () => void, show: boolean,
    success: (props: { loanInfo: LoanInfo, path: string }) => void
}) {
    const [loading, setLoading] = React.useState({message: "", show: false})
    const [transactions, setTransactions] = React.useState(Array<Transaction>())
    const [allocations, setAllocations] = React.useState(Array<Allocation>())

    const currentDate = useAppSelector(getCurrentDate).split(" ")[0]
    const [loan, setLoan] = React.useState({start_date: params.loanInfo.start_date, account_id: 0, customDate: false})
    const handle_loan = (name: string, value: string | number) => {
        setLoan({...loan, [name]: value})
    }

    const disburse_loan = () => {
        const sources = Utils.get_transaction_sources(allocations)

        if (!sources.has_error) {
            if (sources.total !== parseFloat(params.loanInfo.loan_principal)) {
                ToastsStore.error(`Total amount from sources must be ${parseFloat(params.loanInfo.loan_principal).toLocaleString()}`)
            } else if (calculate_amount("charge") > 0 && loan.account_id === 0) {
                ToastsStore.error("Select an account where all charges are deposited")
            } else {
                setLoading({message: "Disbursing loan, please wait", show: true})
                Req.disburse_loan({
                    loan_id: params.loanInfo.loan_id,
                    due_date: params.loanInfo.due_date,
                    start_date: loan.start_date,
                    transactions: JSON.stringify(transactions),
                    sources: JSON.stringify(sources.source),
                    charges_account: loan.account_id
                })
                    .then(response => {
                        setLoading({message: "", show: false})
                        if (response.data.hasOwnProperty("code")) {
                            if (response.data.code === 1) {
                                params.success({
                                    loanInfo: {...params.loanInfo, loan_status: 'active'},
                                    path: `${Req.BASE_URL}${response.data.file}`
                                })
                            } else if (response.data.code === 2) {
                                ToastsStore.error("Loan not found, cannot proceed with disbursing")
                            } else if (response.data.code === 3) {
                                ToastsStore.error("Loan cannot be disbursed, loan status is not 'Verified'")
                            } else if (response.data.code === 4) {
                                ToastsStore.info("Your till account has no rights to disburse this loan")
                            } else if (response.data.code === 5) {
                                ToastsStore.info("Your disbursement source accounts are invalid")
                            } else if (response.data.code === 6) {
                                ToastsStore.info(`${response.data.account} has only ${response.data.balance.toLocaleString()} left`)
                            }
                        } else {
                            ToastsStore.error("Could not disburse please try again")
                        }
                    })
                    .catch(() => {
                        setLoading({message: "", show: false})
                        ToastsStore.error("Could not update disburse loan please retry")
                    })
            }
        }
    }

    const initialise_disburse = () => {
        setLoading({message: "Initialising disbursement, please wait", show: true})
        Req.disburse_loan_info({loan_id: params.loanInfo.loan_id})
            .then((response) => {
                setLoading({message: "", show: false})
                if (response.data.hasOwnProperty("code")) {
                    if (response.data.code === 1) {
                        setTransactions([
                            {
                                type: '+',
                                amount: parseFloat(params.loanInfo.loan_principal),
                                fee_name: "Loan Principal", optional: 0, checked: 1, group: 'principal'
                            },
                            ...response.data.transactions
                        ])
                        setAllocations((response.data.allocations as Allocation[]).filter((allocation) => allocation.balance > 0))
                    } else if (response.data.code === 2) {
                        params.close()
                        ToastsStore.info("Till account has not been activated, you cannot disburse this loan")
                    } else if (response.data.code === 3) {
                        params.close()
                        ToastsStore.error("Tills were already closed, you cannot disburse more loans")
                    }
                } else {
                    params.close()
                    ToastsStore.error("Could not initialise loan information, please retry")
                }
            })
            .catch(() => {
                setLoading({message: "", show: false})
                params.close()
                ToastsStore.error("Could not initialise loan information, please wait")
            })
    }

    const calculate_amount = (type: 'charge' | 'disburse' | 'principal'): number => {
        let total = 0
        transactions.forEach((transaction) => {
            if (transaction.checked === 1) {
                if (type === 'disburse') {
                    total = transaction.type === "+" ? total + transaction.amount : total - transaction.amount
                } else if (type === 'principal' && transaction.type === "+") {
                    total += transaction.amount
                } else if (type === 'charge' && transaction.type === '-') {
                    total += transaction.amount
                }
            }
        })
        return total
    }

    React.useEffect(() => {
        setLoan(({...loan, start_date: params.loanInfo.start_date}))
    }, [params.loanInfo.start_date])

    React.useEffect(() => {
        if (params.show) {
            initialise_disburse()
        }
    }, [params.show])

    React.useEffect(() => {
        setLoan({...loan, account_id: 0})
    }, [allocations])

    const interval_statement = (): string => {
        if (params.loanInfo.payment_interval === 1) {
            return "1 day from now"
        } else if (params.loanInfo.payment_interval === 7) {
            return "7 days from now"
        } else if (params.loanInfo.payment_interval === 14) {
            return "2 weeks from now"
        } else {
            return "1 month from now"
        }
    }

    return (
        <>
            <Loading show={loading.show} text={loading.message} hide={() => setLoading({...loading, show: false})}/>

            <Modal open={params.show} basic size='small' centered={false}>
                <div className="modal_div">
                    <div className="form_header">Disburse Loan</div>

                    <div className="form_content">
                        <div className="row mx-0">
                            <div className={"col-12 col-md-7 pl-md-0 pr-md-1"}>
                                <AllocationTable allocations={allocations} setAllocations={setAllocations}/>

                                {
                                    transactions.length > 0 &&
                                    <div className="table_container mb-3" style={{height: 'auto'}}>
                                        <Table celled striped compact size='small' inverted color='grey' selectable>
                                            <Table.Header fullWidth>
                                                <Table.Row>
                                                    <Table.HeaderCell style={{width: '40px'}}/>
                                                    <Table.HeaderCell style={{width: '170px'}}>Transaction Type</Table.HeaderCell>
                                                    <Table.HeaderCell style={{width: '120px'}}>Actual Amount</Table.HeaderCell>
                                                </Table.Row>
                                            </Table.Header>

                                            <Table.Body>
                                                {
                                                    transactions.map((transaction, index) =>
                                                        <Table.Row key={index}>
                                                            <Table.Cell style={{width: '40px'}} textAlign="center">
                                                                {
                                                                    transaction.optional === 1 &&
                                                                    <Checkbox
                                                                        checked={transaction.checked === 1}
                                                                        onChange={(event, data) =>
                                                                            setTransactions(
                                                                                transactions.map((_t, _i) =>
                                                                                    _i === index ? {..._t, checked: data.checked ? 1 : 0} : _t
                                                                                ))
                                                                        }/>
                                                                }

                                                            </Table.Cell>
                                                            <Table.Cell style={{width: '170px'}}>{transaction.fee_name}</Table.Cell>
                                                            <Table.Cell style={{width: '120px'}}>{Utils.comma_number(transaction.amount)}</Table.Cell>
                                                        </Table.Row>
                                                    )
                                                }
                                            </Table.Body>
                                        </Table>
                                    </div>
                                }
                            </div>

                            <div className={"col-12 col-md-5 pl-md-1 pr-md-0"}>
                                {
                                    calculate_amount("charge") > 0 &&
                                    <div className="row mx-0 mb-3">
                                        <MyLabel title={"Select account receiving the loan fees"}/>
                                        {
                                            allocations
                                                .filter((allocation) => allocation.balance >= calculate_amount('charge'))
                                                .map((allocation) =>
                                                    <div key={allocation.account_id} className={"col-12 px-0 py-1"}>
                                                        <Radio
                                                            label={allocation.account_name}
                                                            disabled={!Utils.is_valid_number(Utils.strip_commas(allocation.amount))}
                                                            checked={loan.account_id === allocation.account_id}
                                                            onChange={(event, data) => {
                                                                if (data.checked) {
                                                                    handle_loan('account_id', allocation.account_id)
                                                                }
                                                            }}/>
                                                    </div>
                                                )
                                        }
                                    </div>
                                }

                                <div className={'mb-3'}>
                                    <MyDateInput
                                        title="Date of first installment payment" value={loan.start_date} minDate={currentDate}
                                        maxDate={moment(currentDate, "YYYY-MM-DD").add('months', 2).format("YYYY-MM-DD")}
                                        name="start_date" placeholder="Select payment start date" disabled={!loan.customDate}
                                        change={(name, value) => setLoan({...loan, start_date: value})}/>
                                    <div className='row m-0 pt-1'>
                                        <div className='col-12 px-0 py-1'>
                                            <Radio
                                                label={`Default date (${moment(params.loanInfo.start_date).format("ll")})`}
                                                checked={!loan.customDate && loan.start_date === params.loanInfo.start_date}
                                                onChange={(event, data) => {
                                                    if (data.checked) {
                                                        setLoan({...loan, customDate: false, start_date: params.loanInfo.start_date})
                                                    }
                                                }}/>
                                        </div>
                                        <div className='col-12 px-0 py-1'>
                                            <Radio
                                                label='Select a new date' checked={loan.customDate}
                                                onChange={(event, data) => {
                                                    if (data.checked) {
                                                        setLoan({...loan, customDate: true})
                                                    } else {
                                                        setLoan({...loan, customDate: false, start_date: params.loanInfo.start_date})
                                                    }
                                                }}/>
                                        </div>
                                        <div className='col-12 px-0 py-1'>
                                            <Radio
                                                label={interval_statement()} checked={!loan.customDate && loan.start_date !== params.loanInfo.start_date}
                                                onChange={(event, data) => {
                                                    if (data.checked) {
                                                        const start_date = moment(currentDate, "YYYY-MM-DD")
                                                            .add('days', params.loanInfo.payment_interval === 30 ? 28 : params.loanInfo.payment_interval)
                                                            .format("YYYY-MM-DD")
                                                        setLoan({...loan, customDate: false, start_date: start_date})
                                                    }
                                                }}/>
                                        </div>
                                    </div>
                                </div>

                                <div className='row mx-0 mb-3'>
                                    <div className='col-6 pl-0 pr-1 disburse_summary' style={{borderRight: "solid 1px #DEDEDE"}}>
                                        <div className="title">Loan Principal</div>
                                        <div className="amount principal">{Utils.comma_number(calculate_amount('principal'))}</div>
                                    </div>
                                    <div className='col-6 pl-1 pr-0'>
                                        <div className="disburse_summary">
                                            <div className="title">Total Charges</div>
                                            <div className="amount charges">{Utils.comma_number(calculate_amount('charge'))}</div>
                                        </div>
                                    </div>
                                    <div className="col-12 px-0 disburse_summary">
                                        <div className="title">Amount to Disburse</div>
                                        <div className="amount">{Utils.comma_number(calculate_amount('disburse'))}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="form_footer">
                        <div className="row mx-0">
                            <div className="col-6 col-md-3 offset-md-3 pl-0 pr-1">
                                <Button negative onClick={() => params.close()} content="Close Window" size='mini' fluid
                                        icon='close' labelPosition={"left"}/>
                            </div>
                            <div className="col-6 col-md-3 pl-1 pr-0">
                                <Button positive onClick={disburse_loan} content="Disburse Loan" size='mini' fluid
                                        icon="money" labelPosition={"left"}/>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}
