import {Req} from "../../../../utils/Req";
import {ToastsStore} from "react-toasts";
import {Loading, MyLabel} from "../../../../utils/Components";
import React from "react";
import {Button, Form, Modal, Table, TextArea} from "semantic-ui-react";
import {LoanInfo} from "../ShowLoan";
import Utils from "../../../../utils/Utils";

export default function BadLoan(params: { show: boolean, loanInfo: LoanInfo, close: (loanInfo?: LoanInfo) => void }) {
    const [loading, setLoading] = React.useState({show: false, title: ""})
    const [edit, setEdit] = React.useState({reason: ''})

    const make_bad_loan = () => {
        if (edit.reason.trim().length < 10) {
            ToastsStore.error("Enter a valid reason for deleting the loan")
        } else {
            setLoading({show: true, title: "Deleting loan, please wait"})
            Req.make_bad_loan({
                loan_id: params.loanInfo.loan_id, reason: edit.reason,
                principal: params.loanInfo.payments.summary.principal.balance,
                interest: params.loanInfo.payments.summary.interest.balance,
                penalty: params.loanInfo.payments.summary.penalties?.balance ?? 0,
                charges: params.loanInfo.payments.summary.charges?.balance ?? 0
            })
                .then((response) => {
                    setLoading({show: false, title: ""})
                    if (response.data.hasOwnProperty("code")) {
                        if (response.data.code === 1) {
                            ToastsStore.success("Loan made bad debt successfully")
                            params.close(response.data.loan)
                        } else if (response.data.code === 2) {
                            ToastsStore.error("Loan cannot be made a bad debt")
                            params.close()
                        }
                    } else {
                        ToastsStore.error("Error while making loan a bad debt, please retry")
                    }
                })
                .catch(() => {
                    setLoading({show: false, title: ""})
                    ToastsStore.error("Error while making loan a bad debt, please retry")
                })
        }
    }

    React.useEffect(() => {
        setEdit({reason: ""})
    }, [params.show])

    return (
        <>
            <Loading show={loading.show} text={loading.title}/>

            <Modal open={params.show} basic size='mini' centered={false}>
                <div className="modal_div">
                    <div className="form_header">Make Bad Debt</div>

                    <div className="form_content">
                        <div className="table_container mb-2" style={{height: 'auto'}}>
                            <Table celled striped compact size='small' inverted color='grey' selectable className='has_total'>
                                <Table.Header fullWidth>
                                    <Table.Row>
                                        <Table.HeaderCell style={{width: '70px'}}>Item Name</Table.HeaderCell>
                                        <Table.HeaderCell style={{width: '140px'}}>Item Balance</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {
                                        [
                                            {name: "Principal", balance: params.loanInfo.payments.summary.principal.balance},
                                            {name: "Interest", balance: params.loanInfo.payments.summary.interest.balance},
                                            {name: "Penalties", balance: params.loanInfo.payments.summary.penalties?.balance ?? 0},
                                            {name: "Charges", balance: params.loanInfo.payments.summary.charges?.balance ?? 0},
                                            {
                                                name: "Total", balance:
                                                    (params.loanInfo.payments.summary.charges?.balance ?? 0) +
                                                    (params.loanInfo.payments.summary.charges?.balance ?? 0) +
                                                    params.loanInfo.payments.summary.interest.balance +
                                                    params.loanInfo.payments.summary.principal.balance
                                            }
                                        ]
                                            .map((item, index) =>
                                                <Table.Row key={index}>
                                                    <Table.Cell style={{width: '70px'}}>{item.name}</Table.Cell>
                                                    <Table.Cell style={{width: '140px'}}>{Utils.comma_number(item.balance)}</Table.Cell>
                                                </Table.Row>
                                            )
                                    }
                                </Table.Body>
                            </Table>
                        </div>

                        <MyLabel title="Reason for making loan a bad debt"/>
                        <Form>
                            <TextArea
                                placeholder='Enter reason for making loan a bad debt' rows={3} value={edit.reason}
                                onChange={((_event, data) => setEdit({reason: data.value as string}))}/>
                        </Form>
                    </div>

                    <div className="form_footer mt-3">
                        <div className="row m-0">
                            <div className="col-6 pl-0 pr-1">
                                <Button negative onClick={() => params.close()} content="Close Window" size='mini' fluid
                                        icon='close' labelPosition={"left"}/>
                            </div>
                            <div className="col-6 pl-1 pr-0">
                                <Button positive onClick={make_bad_loan} content="Make Bad Debt" size='mini' fluid
                                        icon="trash" labelPosition={"left"}/>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}
