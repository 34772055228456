import {MyDateInput, MySelect} from "../../../utils/Components";
import {multiselect_type} from "../../../utils/components/MultiSelect";
import {Button} from "semantic-ui-react";
import Utils from "../../../utils/Utils";
import React from "react";
import {Req} from "../../../utils/Req";
import {ToastsStore} from "react-toasts";
import ReconcileCreditors, {CreditorReconcile} from "./reconcile/ReconcileCreditors";
import RcCreditorPayments, {RcCreditorPayment} from "./reconcile/RcCreditorPayments";
import RcAccountTransfers, {RcAccountTransfer} from "./reconcile/RcAccountTransfers";
import RcTillAllocations, {RcTillAllocation} from "./reconcile/RcTillAllocations";
import RcAccountFees, {RcAccountFee} from "./reconcile/RcAccountFees";
import RcDisbursements, {RcDisbursement} from "./reconcile/RcDisbursements";
import RcCharges, {RcCharge} from "./reconcile/RcCharges";
import RcLoanPayments, {RcLoanPayment} from "./reconcile/RcLoanPayments";
import RcExpenses, {RcExpense} from "./reconcile/RcExpenses";
import {Account} from "../../../utils/Models";

interface Reconciliation {
    creditors?: CreditorReconcile
    creditor_payments?: RcCreditorPayment[]
    account_transfers?: RcAccountTransfer[]
    till_allocation?: RcTillAllocation[]
    account_fees?: RcAccountFee[]
    disbursements?: RcDisbursement[]
    loan_charges?: RcCharge[]
    loan_payments?: RcLoanPayment[]
    expenses?: { data: RcExpense[], sources: RcSource[] }
}

export interface RcSource {
    ac: number
    id: number
    amount: number
}

export default function AccountReconciliations(params: { accounts: Account[] }) {
    const [reconciliation, setReconciliation] = React.useState<Reconciliation>({})
    const [journalAccounts, setJournalAccounts] = React.useState(Array<Account>())

    const [searching, setSearching] = React.useState(false)
    const [search, setSearch] = React.useState({min_date: Utils.weekly_date().sunday, max_date: Utils.today(), type: ''})
    const handle_search = (name: string, value: string | number | multiselect_type) => {
        setSearch({...search, [name]: value})
    }

    const get_reconciliations = () => {
        if (search.type === "") {
            ToastsStore.error("Select an account to reconcile")
        } else {
            setSearching(true)
            Req.get_account_reconciliations({
                min_date: search.min_date, max_date: search.max_date, types: JSON.stringify([search.type])
            })
                .then((response) => {
                    setSearching(false)
                    if (response.data.hasOwnProperty("reconciliations")) {
                        setReconciliation(response.data.reconciliations)
                    } else {
                        ToastsStore.error("Error loading reconciliations")
                    }
                })
                .catch(() => {
                    setSearching(false)
                    ToastsStore.error("Error loading reconciliations")
                })
        }
    }

    React.useEffect(() => {
        Req.get_journal_accounts()
            .then((response) => {
                if (response.data.hasOwnProperty("accounts")) {
                    setJournalAccounts(response.data.accounts)
                }
            })
    }, [])

    return (
        <>
            <div className={'p-2'}>
                <div className="content_bar">
                    <div className="search_bar">
                        <div style={{maxWidth: '220px', width: '220px'}}>
                            <MySelect
                                change={(value) => handle_search('type', value as string)} value={search.type}
                                placeholder={"Select an account to reconcile"}
                                options={[
                                    {text: 'Select an account to reconcile', value: ''},
                                    {text: 'Creditors', value: 'Creditors'},
                                    {text: 'Creditor Payments', value: 'Creditor Payments'},
                                    {text: 'Account Transfers', value: 'Account Transfers'},
                                    {text: 'Till Allocation', value: 'Till Allocation'},
                                    {text: 'Account Opening Fees', value: 'Account Opening Fees'},
                                    {text: 'Disbursements', value: 'Disbursements'},
                                    {text: 'Loan Charges', value: 'Loan Charges'},
                                    {text: 'Loan Payments', value: 'Loan Payments'},
                                    {text: 'Expenses', value: 'Expenses'}
                                ]}/>
                        </div>
                        <div>
                            <MyDateInput
                                value={search.min_date} name="min_date" maxDate={search.max_date}
                                placeholder="Select min date" change={handle_search}/>
                        </div>
                        <div>
                            <MyDateInput
                                value={search.max_date} name="max_date" minDate={search.min_date}
                                placeholder="Select max date" change={handle_search}/>
                        </div>
                    </div>

                    <div className="button_bar">
                        <Button
                            size='mini' content="Search" primary labelPosition="left" loading={searching}
                            disabled={searching} icon="search" onClick={() => get_reconciliations()}/>
                    </div>
                </div>
            </div>

            {
                reconciliation.creditors !== undefined &&
                <ReconcileCreditors data={reconciliation.creditors} accounts={params.accounts}/>
            }

            {
                reconciliation.creditor_payments !== undefined &&
                <RcCreditorPayments payments={reconciliation.creditor_payments} accounts={journalAccounts}/>
            }

            {
                reconciliation.account_transfers !== undefined &&
                <RcAccountTransfers transfers={reconciliation.account_transfers} accounts={journalAccounts}/>
            }

            {
                reconciliation.till_allocation !== undefined &&
                <RcTillAllocations allocations={reconciliation.till_allocation} accounts={journalAccounts}/>
            }

            {
                reconciliation.account_fees !== undefined &&
                <RcAccountFees fees={reconciliation.account_fees} accounts={journalAccounts}/>
            }

            {
                reconciliation.disbursements !== undefined &&
                <RcDisbursements disbursements={reconciliation.disbursements} accounts={journalAccounts}/>
            }

            {
                reconciliation.loan_charges !== undefined &&
                <RcCharges charges={reconciliation.loan_charges} accounts={journalAccounts}/>
            }

            {
                reconciliation.loan_payments !== undefined &&
                <RcLoanPayments payments={reconciliation.loan_payments} accounts={journalAccounts}/>
            }

            {
                reconciliation.expenses !== undefined &&
                <RcExpenses
                    expenses={reconciliation.expenses.data} journalAccounts={journalAccounts}
                    sources={reconciliation.expenses.sources} accounts={params.accounts}/>
            }
        </>
    )
}
