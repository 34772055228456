import {LoanInfo} from "../ShowLoan";
import {Req} from "../../../../utils/Req";
import {ToastsStore} from "react-toasts";
import {Loading, MyInput, MyLabel} from "../../../../utils/Components";
import React from "react";
import Utils from "../../../../utils/Utils";
import {Button, Modal} from "semantic-ui-react";
import PhoneInput from "react-phone-input-2";

export default function DummySchedule(params: { show: boolean, loanInfo: LoanInfo, close: (path?: string) => void }) {
    const [loading, setLoading] = React.useState({message: "", show: false})
    const [client, setClient] = React.useState({name: "", contact: ""})
    const handle_change = (name: string, value: string) => {
        setClient({...client, [name]: value})
    }

    const print_schedule = () => {
        Req.print_provisional_schedule({
            charges: JSON.stringify(
                params.loanInfo.charges.map((charge) => ({
                    charge_id: charge.charge_id,
                    charge_type: charge.charge_type,
                    charge_value: charge.charge_value,
                    charge_amount: parseFloat(charge.charge_amount.toString())
                }))
            ),
            principal: parseFloat(Utils.strip_commas(params.loanInfo.loan_principal)),
            schedules: JSON.stringify(params.loanInfo.schedules.expected),
            contact: client.contact,
            name: client.name
        })
            .then(response => {
                setLoading({message: "", show: false})
                if (response.data.hasOwnProperty("code")) {
                    switch (response.data.code) {
                        case 1:
                            params.close(response.data.file)
                            ToastsStore.success("Loan has been saved successfully")
                            break;
                        default:
                            ToastsStore.error("Error while printing schedule, please retry")
                            break;
                    }
                } else {
                    ToastsStore.error("Error while saving loan, please retry")
                }
            })
            .catch(() => {
                setLoading({message: "", show: false})
                ToastsStore.error("Error while saving loan, please retry")
            })
    }

    return (
        <>
            <Loading show={loading.show} text={loading.message} hide={() => setLoading({...loading, show: false})}/>

            <Modal open={params.show} basic size='mini' centered={false}>
                <div className="modal_div">
                    <div className="form_header">Generate Provisional Schedule</div>

                    <div className="form_content">
                        <MyInput placeholder="Enter client name" title="Client Name" name="name" change={handle_change} value={client.name}/>

                        <MyLabel title="Client Contact"/>
                        <PhoneInput
                            countryCodeEditable={false} containerStyle={{marginBottom: '0px'}} country={'ug'}
                            value={client.contact} onlyCountries={['ug']} onChange={(value) => handle_change('contact', value)}/>
                    </div>

                    <div className="form_footer mt-3">
                        <div className="row m-0">
                            <div className="col-6 pl-0 pr-1">
                                <Button negative onClick={() => params.close()} content="Close Window" size='mini' fluid icon='close' labelPosition={"left"}/>
                            </div>
                            <div className="col-6 pl-1 pr-0">
                                <Button positive onClick={print_schedule} content="Print Schedule" size='mini' fluid icon="print" labelPosition={"left"}/>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}
