import {Fetch, Page, Req} from "./Req";
import {SyntheticEvent} from "react";
import moment from "moment"
import {SelectData} from "./Components"
import {UserRights} from "../store/slices/userSlice";
import {LoanType} from "../modules/admin/loans/LoanTypes";
import {Office} from "../modules/admin/users/Offices";
import {SystemClient} from "../modules/clients/CreateClient";
import {LoanInfo} from "../modules/loans/container/ShowLoan";
import {MultiUser, SystemUser} from "../modules/admin/users/Users";
import logo from "../../assets/images/logo.png"
import {ToastsStore} from "react-toasts";
import {Allocation, AllocationSource} from "./Models";

export interface ConfirmAction {
    open: boolean
    cancelButton: string
    confirmButton: string
    content: string | any
    onConfirm?: (event?: SyntheticEvent, data?: object) => void
    onCancel?: (event?: SyntheticEvent, data?: object) => void
}

export const initial_confirm: ConfirmAction = {cancelButton: "Cancel", confirmButton: "Proceed", content: "", open: false}

export class InputFile {
    file_src: string;
    file: File | undefined;

    constructor(file_path: string, file ?: File) {
        this.file_src = file_path;
        this.file = file
    }
}

export default class Utils {
    static genders = [{text: "Other", value: "Other"}, {text: "Female", value: "Female"}, {text: "Male", value: "Male"}]
    static ids = [
        {text: "None", value: "None"},
        {text: "Driving Permit", value: "Driving Permit"},
        {text: "National ID", value: "National ID"},
        {text: "Passport", value: "Passport"},
        {text: "Refugee ID", value: "Refugee ID"},
    ]
    static guarantor_relationships = [
        {text: "Other", value: ""},
        {text: "Child", value: "Child"},
        {text: "Business Associate", value: "Business Associate"},
        {text: "Sibling", value: "Sibling"},
        {text: "Spouse", value: "Spouse"},
        {text: "Parent or Guardian", value: "Parent or Guardian"},
    ]

    static today = (initial?: Date) => {
        if (initial === undefined) {
            initial = new Date()
        }
        const year = initial.getFullYear()
        let month = initial.getMonth() + 1
        const date = initial.getDate()
        return year + "-" + (month < 10 ? "0" + month : month) + "-" + (date < 10 ? "0" + date : date)
    }

    static max_month_date = Utils.today(new Date((new Date()).getFullYear(), (new Date()).getMonth() + 1, 0))
    static min_month_date = Utils.today(new Date((new Date()).getFullYear(), (new Date()).getMonth(), 1))

    static month_start_date = () => {
        return Utils.today(new Date((new Date()).getFullYear(), (new Date()).getMonth(), 1))
    }

    static weekly_date = (): { sunday: string; saturday: string } => {
        const sunday = new Date();
        sunday.setDate(sunday.getDate() - (sunday.getDay()))

        const saturday = new Date()
        saturday.setDate(sunday.getDate() + 6)

        return {
            sunday: (sunday.toISOString().substr(0, 10)),
            saturday: (saturday.toISOString().substr(0, 10))
        }
    }

    static weekly_range = Utils.weekly_date()

    static format_file(file: File) {
        return new Promise<InputFile>((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                resolve(new InputFile(reader.result as string, file))
            };
            reader.onerror = error => reject(null);
        });
    }

    static download_file(dataUrl: string) {
        // Construct the 'a' element
        let link = document.createElement("a")
        link.target = "_blank"

        // Construct the URI
        link.href = `${Req.BASE_URL}${dataUrl}`
        document.body.appendChild(link)
        link.click()

        // Cleanup the DOM
        document.body.removeChild(link)
    }

    static row_number(index: number, fetch?: Fetch | Page) {
        let number = (index + 1)
        if (fetch !== undefined) {
            number = (index + 1) + (fetch.limit * (fetch.page - 1))
        }
        return number.toLocaleString()
    }

    public static is_valid_number(value: string): boolean {
        return ((value != null) && (value !== '') && !isNaN(Number(value.toString())));
    }

    public static invalid_email(email: string): boolean {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return !regex.test(email);
    }

    public static invalid_full_name(name: string): boolean {
        const regex = /^[a-zA-Z ]{2,100}$/;
        return !regex.test(name);
    }

    public static invalid_address(name: string): boolean {
        const regex = /^[a-zA-Z\d ]{2,100}$/;
        return !regex.test(name);
    }

    public static invalid_name(name: string): boolean {
        const regex = /^[a-zA-Z]{2,30}$/;
        return !regex.test(name);
    }

    public static invalid_contact(contact: string): boolean {
        return contact.replace(/\D/g, '').length !== 12
    }

    static roundToTwo = (num: number, decimals?: string): number => {
        if (decimals === undefined) {
            decimals = "2";
        }
        return +(Math.round(parseFloat(num + `e+${decimals}`)) + `e-${decimals}`);
    }

    public static comma_number(value: number | string, pre ?: string, post ?: string): string {
        if (!this.is_valid_number(value.toString())) {
            return value.toString()
        }
        const prefix = pre === undefined ? 'shs ' : pre
        const postfix = post === undefined ? '' : post
        return prefix + Utils.roundToTwo(value as number, "2").toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + postfix
    }

    public static comma_input(value: string | number): string {
        let number = Utils.strip_commas(value)
        if (Utils.is_valid_number(number) && number.substr(number.length - 1) !== ".") {
            return number.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        } else {
            return value.toString()
        }
    }

    public static strip_commas(number: string | number) {
        return number.toString().replace(/[^\d.-]/g, '').trim()
    }

    public static localise_date(string: string): string {
        if (string === "") {
            return ""
        }
        return moment(string, "YYYY-MM-DD").format("ddd, DD MMM YYYY")
    }

    public static localise_date_time(string: string): string {
        return moment(string, "YYYY-MM-DD hh:mm:ss").format("ddd, DD MMM YYYY hh:mm A")
    }

    public static format_payment_date = (current: string, months?: number, days?: number) => {
        const date = moment(current + " 23:59:59", "YYYY-MM-DD hh:mm:ss")
        if (months !== undefined) {
            date.add(1, "month")
        }
        if (days !== undefined) {
            date.add(days, "days")
        }
        return date.format("YYYY-MM-DD")
    }

    public static display_notification(params: { notification_title: string, notification_message: string }) {
        const display = () => {
            const notify = new Notification(`${params.notification_title}`, {body: `${params.notification_message}`, icon: logo})
            notify.onclick = () => {
                console.log("clicked")
            }
        }

        if (window.Notification) {
            if (Notification.permission === 'granted') {
                display()
            } else {
                Notification.requestPermission()
                    .then(function (p) {
                        if (p === 'granted') {
                            display()
                        } else {
                            console.log('User blocked notifications.');
                        }
                    })
                    .catch(function (err) {
                        console.error(err);
                    });
            }
        }
    }

    static user_token() {
        const user_token = localStorage.getItem("user_token")
        return user_token === null ? "" : user_token.toString()
    }

    static get_loan_type(loanTypes: Array<LoanType>, typeID: number): null | LoanType {
        for (let index = 0; index < loanTypes.length; index++) {
            if (loanTypes[index].type_id === typeID) {
                return loanTypes[index]
            }
        }
        return null
    }

    static combo_office = (offices: Array<{ office_id: number, office_name: string }>, initial?: SelectData): Array<SelectData> => {
        let select_info: Array<SelectData> = [];
        if (initial) {
            select_info = [...select_info, initial]
        }
        offices.forEach(office => {
            select_info = [...select_info, {text: office.office_name, value: office.office_id}]
        })
        return select_info
    }

    static get_accessible_office = (offices: Array<Office>, user_rights: UserRights, right: string)
        : { select: Array<SelectData>; selected: Array<number> } => {
        return Utils.format_accessible_office(offices, user_rights, right)
    }

    static format_accessible_office = (offices: Array<{ office_id: number, office_name: string }>, user_rights: UserRights, right: string) => {
        let access: Array<number> = []
        let branches: Array<number> = []
        if (user_rights.hasOwnProperty(right)) {
            access = user_rights[right]
        }
        let select_info: Array<SelectData> = [];
        offices.forEach(office => {
            if (access.includes(office.office_id)) {
                select_info = [...select_info, {text: office.office_name, value: office.office_id}]
                branches = [...branches, office.office_id]
            }
        })
        return {select: select_info, selected: branches}
    }

    static get_initial_office = (user_rights: UserRights, right: string): number => {
        let initial_office = 0
        if (user_rights.hasOwnProperty(right) && user_rights[right].length > 0) {
            initial_office = user_rights[right][0]
        }
        return initial_office
    }


    static get_users = (systemUsers: Array<SystemUser>, initial?: SelectData): Array<SelectData> => {
        const select_info: Array<SelectData> = [];
        if (initial !== undefined) {
            select_info.push(initial)
        }
        systemUsers.forEach(user => {
            select_info.push({text: user.user_name, value: user.user_id})
        })
        return select_info
    }

    static get_clients = (systemClients: Array<SystemClient>, initial?: SelectData): Array<SelectData> => {
        const select_info: Array<SelectData> = [];
        if (initial !== undefined) {
            select_info.push(initial)
        }
        systemClients.forEach(user => {
            select_info.push({text: user.client_name, value: user.client_id})
        })
        return select_info
    }

    static get_user = (users: Array<SelectData>, user_id: number): SelectData => {
        for (let index = 0; index < users.length; index++) {
            if (users[index].value === user_id) {
                return users[index]
            }
        }
        return {text: "", value: user_id}
    }

    static get_multi_users = (params: { users: Array<MultiUser>, offices: Array<number>, selected: Array<number> }) => {
        const in_list = params.users.filter((value) => params.offices.includes(value.office_id))
        const users: Array<SelectData> = in_list.map((user) => ({value: user.value, text: user.text}))
        const ids: Array<number> = in_list.map((user) => user.value)
        const selected = params.selected.filter((value) => ids.includes(value))
        return {users: users, selected: selected}
    }

    static format_loan_data = (response: any): LoanInfo => {
        const info = response.data.loan
        return {
            client_id: info.client_id,
            client_info: info.client_info,
            charges: info.charges,
            due_date: info.due_date,
            guarantors: info.guarantors,
            loan_period: info.loan_period,
            loan_principal: info.loan_principal,
            loan_rate: info.loan_rate,
            loan_status: info.loan_status,
            loan_type: info.loan_type,
            payment_interval: info.payment_interval,
            projected_interest: info.projected_interest,
            rate_type: info.rate_type,
            schedules: info.schedules,
            start_date: info.start_date,
            loan_id: info.loan_id,
            payments: info.payments,
            securities: info.securities,
            broker: {
                broker_name: info.broker.broker_name,
                broker_contact: Utils.invalid_contact(info.broker.broker_contact.toString()) ? '' : `+${info.broker.broker_contact}`,
                broker_commission: info.broker.broker_commission
            },

            others: {
                extension: info.others.extension,
                penalties: info.others.penalties,
                removals: info.others.removals,
                reschedule: info.others.reschedule,
                loan_statuses: info.others.loan_statuses,
                journals: info.others.journals
            },
            inspector_id: info.inspector_id,
            office_id: info.office_id,
        }
    }

    static get_transaction_sources = (allocations: Array<Allocation>) => {
        let has_error = false
        let total = 0
        const source: AllocationSource = {}

        for (let index = 0; index < allocations.length && !has_error; index++) {
            const amount = Utils.strip_commas(allocations[index].amount.trim())
            if (amount !== "") {
                const allocation = allocations[index];
                if (!Utils.is_valid_number(amount) || parseFloat(amount) <= 0) {
                    has_error = true
                    ToastsStore.error(`Enter valid amount used for '${allocation.account_name}'`)
                } else if (parseFloat(amount) > allocation.balance) {
                    has_error = true
                    ToastsStore.error(`A maximum of '${allocation.balance.toLocaleString()}' can be used from '${allocation.account_name}'`)
                } else {
                    total += parseFloat(amount)
                    source[allocation.account_id] = parseFloat(amount)
                }
            }
        }

        return {total: total, has_error: has_error, source: source}
    }

    static get_journal_account = (account_id: number, accounts: { account_id: number, account_name: string }[]) => {
        return accounts.filter((account) => account.account_id === account_id)[0]?.account_name
    }
}
