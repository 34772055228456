import {initial_charge, LoanChargeTier, LoanTypeCharge} from "./LoanTypes";
import {Button, Form, Modal, Table, TextArea} from "semantic-ui-react";
import React from "react";
import {Loading, MyInput, MyLabel} from "../../../utils/Components";
import Utils from "../../../utils/Utils";
import {ToastsStore} from "react-toasts";
import {Req} from "../../../utils/Req";
import EditCharge from "./EditCharge";

export default function LoanCharges(params: {
    show: boolean, close: (charges?: LoanTypeCharge[]) => void, loanTypeCharge: LoanTypeCharge
}) {
    const [loading, setLoading] = React.useState({show: false, title: ""})

    const [charge, setCharge] = React.useState(initial_charge)
    const handle_charge = (name: string, value: string) => {
        setCharge({...charge, [name]: value})
    }

    const initialTier: LoanChargeTier = {charge_id: 0, charge_type: 'Flat', charge_value: '', min_value: ''}
    const [edit, setEdit] = React.useState({index: -1, tier: initialTier, show: false})


    const save_charges = () => {
        if (charge.fee_name.trim().length < 3) {
            ToastsStore.error("Enter a valid fee name at least 3 characters")
        } else if (charge.fee_description.trim().length < 10) {
            ToastsStore.error("Enter a valid description at least 10 characters")
        } else {
            setLoading({show: true, title: "Saving loan charges, please wait"})
            Req.save_loan_charges({
                charges: JSON.stringify(
                    charge.tiers.map((tier) => ({
                        min_value: Utils.strip_commas(tier.min_value),
                        charge_value: Utils.strip_commas(tier.charge_value),
                        charge_type: tier.charge_type
                    }))),
                type_id: charge.type_id,
                fee_name: charge.fee_name,
                fee_description: charge.fee_description,
                charge_id: charge.charge_id
            })
                .then((response) => {
                    setLoading({show: false, title: ""})
                    if (response.data.hasOwnProperty("code") && response.data.code === 1) {
                        params.close(response.data.charges)
                    } else {
                        ToastsStore.error("Error while saving charges, please retry")
                    }
                })
                .catch(() => {
                    setLoading({show: false, title: ""})
                    ToastsStore.error("Error while saving charges, please retry")
                })
        }
    }

    React.useEffect(() => {
        setCharge(params.loanTypeCharge)
    }, [params.loanTypeCharge])

    return (
        <>
            <Loading show={loading.show} text={loading.title} hide={() => setLoading({...loading, show: false})}/>

            <EditCharge
                index={edit.index} tier={edit.tier} show={edit.show}
                close={(tier) => {
                    if (tier !== undefined) {
                        if (edit.index === -1) {
                            setCharge({...charge, tiers: [...charge.tiers, tier]})
                        } else {
                            setCharge({...charge, tiers: charge.tiers.map((aTier, index) => index === edit.index ? tier : aTier)})
                        }
                    }
                    setEdit({index: -1, tier: initialTier, show: false})
                }}/>


            <Modal open={params.show} basic size='mini' centered={false}>
                <div className='modal_div row m-0'>
                    <div className="col-12 p-1">
                        <div className="form">
                            <div className="form_content">
                                <MyInput placeholder="Enter charge name" title="Charge Name" name="fee_name"
                                         change={handle_charge} value={charge.fee_name}/>

                                <Form>
                                    <MyLabel title="Loan charge description"/>
                                    <TextArea placeholder='Enter loan charge description' rows={3} value={charge.fee_description}
                                              onChange={(event, data) => handle_charge('fee_description', data.value as string)}/>
                                </Form>
                            </div>

                            <div className="table_container mt-2">
                                <Table celled striped compact size='small' inverted color='grey' selectable unstackable={true}>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell style={{width: '40px'}}/>
                                            <Table.HeaderCell style={{width: '110px'}}>Minimum Principal</Table.HeaderCell>
                                            <Table.HeaderCell style={{width: '90px'}}>Charge</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>

                                    <Table.Body>
                                        {
                                            charge.tiers.map((tier, index) =>
                                                <Table.Row key={index}>
                                                    <Table.Cell style={{width: '40px'}}>
                                                        {/* <Button
                                                            negative size='mini' icon='trash' compact disabled={true}
                                                            onClick={() => setCharge({...charge, tiers: charge.tiers.filter((_aTier, aIndex) => aIndex !== index)})}/>*/}

                                                        <Button
                                                            positive size='mini' icon='edit' compact
                                                            onClick={() => setEdit({index: index, tier: tier, show: true})}/>
                                                    </Table.Cell>
                                                    <Table.Cell style={{width: '110px'}}>
                                                        {Utils.comma_number(parseFloat(tier.min_value as string))}
                                                    </Table.Cell>
                                                    <Table.Cell style={{width: '90px'}}>
                                                        {
                                                            tier.charge_type === 'Flat' ? Utils.comma_number(Utils.strip_commas(tier.charge_value as string)) :
                                                                (tier.charge_type === 'Percentage' ? `${tier.charge_value}%` : 'Manually Entered')
                                                        }
                                                    </Table.Cell>
                                                </Table.Row>
                                            )
                                        }
                                    </Table.Body>
                                </Table>
                            </div>


                            <div className="form_footer">
                                <div className='row m-0'>
                                    <div className='col-5 px-0'>
                                        <Button negative onClick={() => params.close()} content='Discard' fluid size='tiny' icon='close' labelPosition='left'/>
                                    </div>
                                    <div className='col-5 px-1'>
                                        <Button positive onClick={save_charges} content='Save' fluid size='tiny' icon='save' labelPosition='left'/>
                                    </div>
                                    <div className='col-2 px-0'>
                                        <Button positive fluid size='tiny' icon='add' onClick={() => setEdit({index: -1, tier: initialTier, show: true})}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}
